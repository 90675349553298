import axios from "axios";
import toast from 'react-hot-toast';
import { phpSessGet, phpSessSet, phpSessLogout, phpSessIsLoggedIn } from './PhpSession.js'
// import gSessionID from '../index.js'

// -----------------------------------------------------------------------------------------------

// const BASE_URL = "https://jmc2.lakeshoresoftwareinc.com/";
// const BASE_URL = "https://mydesign99.com/";
const BASE_URL = "/";

const imgUrlPrefix = () => {
    return "https://mydesign99.com/";
}

// -----------------------------------------------------------------------------------------------

const buildURL = (route) => {
    return BASE_URL + route;
};

// -----------------------------------------------------------------------------------------------

const apiGetAllAssets = (callback) => {
    const fullURL = buildURL("api/assets/list");
    
    simpleGet(fullURL, callback);
}

// -----------------------------------------------------------------------------------------------

const apiGetDashboardData = (callback) => {
    const fullURL = buildURL("api/dashboard/get");
    
    simpleGet(fullURL, callback);
}

// -----------------------------------------------------------------------------------------------

const apiGetOneAsset = (assetID, callback) => {
    const fullURL = buildURL("api/asset/get/" + assetID);
    
    simpleGet(fullURL,callback);
}

// -----------------------------------------------------------------------------------------------

const apiGetAuthTokenForUser = (callback) => {
	const fullURL = buildURL("api/user/authtoken");
    
	simpleGet(fullURL,callback);
}

// -----------------------------------------------------------------------------------------------

const apiSaveOneAsset = (json_post, callback) => {
    const fullURL = buildURL("api/asset/set");

    simplePost(fullURL, json_post, callback);
}

// -----------------------------------------------------------------------------------------------

const apiLogin = (json_post, callback) => {
    const fullURL = buildURL("api/login");
    
    simplePost(fullURL, json_post, callback);
}


// -----------------------------------------------------------------------------------------------

const apiLogout = () => {
    const fullURL = buildURL("api/logout");

    phpSessLogout();
    simpleGet(fullURL, null);
    // document.getElementById("sess_id").value = "dummy";
}

// -----------------------------------------------------------------------------------------------

const apiSignUp = (json_post, callback) => {
    const fullURL = buildURL("api/user/add");
    
    simplePost(fullURL, json_post, callback);
}

// -----------------------------------------------------------------------------------------------

const apiRequestPwdReset = (json_post, callback) => {
    const fullURL = buildURL("api/pwdreset/request");
    
    simplePost(fullURL, json_post, callback);
}

// -----------------------------------------------------------------------------------------------

const apiUpdatePwd = (json_post, callback) => {
    const fullURL = buildURL("api/pwdreset/update");
    
    simplePost(fullURL, json_post, callback);
}

// -----------------------------------------------------------------------------------------------

const apiAddAsset = (json_post, callback) => {
    const fullURL = buildURL("api/asset/add");
    
    simplePost(fullURL, json_post, callback);
}

// -----------------------------------------------------------------------------------------------

const apiDeleteAsset = (assetId, password, callback) => {
    const fullURL = buildURL("api/asset/delete");
    const postData = {
        asset_id: assetId,
        pwd: password,
        lsi_session_id: phpSessGet()
    };
    // console.log("Deleting asset with ID:", assetId);
    simplePost(fullURL, postData, callback);
}


// -----------------------------------------------------------------------------------------------

const isLoggedIn = () => {
    return phpSessIsLoggedIn();
    // const sessionID = document.getElementById("sess_id").value;
    // if (sessionID == "dummy") {
    //     return false;
    // }
    // return true;
}

// -----------------------------------------------------------------------------------------------

const simpleGet = (fullURL, callback) => {
    const sessionID = phpSessGet();
    // const sessionID = document.getElementById("sess_id").value;
    fullURL = fullURL + "?lsi_session_id=" + sessionID + "&ts=" + Date.now()
    // console.log("fullURL:", fullURL)
    axios.get(fullURL)
        .then((response) => {
            // console.log("Got good response - Ready to Process")
            processGoodResponse(response, callback);
        })
        .catch((error) => {
            // console.log("Unknown Error in Axios GET")
            toast.error("Invalid Response(GET) ", error);
            return;
        });
}

// -----------------------------------------------------------------------------------------------

const simplePost = (fullURL, post_data, callback) => {
    const sessionID = phpSessGet();
    post_data.lsi_session_id = sessionID;

    // console.log("POST data:", post_data);
    // console.log("session ID:", post_data);
    // console.log("calling:", fullURL);

    axios.post(fullURL, post_data) // removed { post_data } wrapper
        .then((response) => {
            // console.log("response:", response);
            // console.log("POST response:", response);
            processGoodResponse(response, callback);

        })
        .catch((error) => {
            // console.log("post failed:", fullURL);
            // console.error("POST error:", error);
            if (error && error.response) {
                toast.error("API call failed: " + error.response.data.message);
            } else {
                toast.error("API call failed: Unknown error(TOP LEVEL)");
            }
        });
};


// -----------------------------------------------------------------------------------------------

const processGoodResponse = (response, callback) => {
    // console.log("response:", response)

    if (!response.hasOwnProperty("data")) {
        toast.error('Invalid Response Format(1).');
        return;
    }
    // console.log("Success - Has Data Property");
    if (!response.data.hasOwnProperty("is_success")) {
        toast.error('Invalid Response Format(2).');
        return;
    }
    // console.log("Success - Has Is Success Property");
    if (response.data.is_success != "1") {
        if (!response.data.hasOwnProperty("err_msg")) {
            toast.error('Invalid Response Format(3).');
        } else {
            toast.error(response.data.err_msg);
        }
        return;
    }
    // console.log("Success is 1");
    if (callback == null) {
        // console.log("Success - Callback in NULL");
        return
    }
    if (!response.data.hasOwnProperty("data")) {
        toast.error('Invalid Response Format(4).');
        return;
    }
    // console.log("Success - Has Inner Data Property");

    if (response.data.data.hasOwnProperty("lsi_session_id")) {
        // console.log("Success - Attempting to Set SessionId");
        phpSessSet(response.data.data.lsi_session_id);
    }
    // console.log("Success - Attempting to Call Callback");
    callback(response.data.data);
};

// -----------------------------------------------------------------------------------------------



export { apiGetAllAssets, apiGetOneAsset, apiSaveOneAsset, apiLogin, apiSignUp, apiAddAsset, apiLogout, isLoggedIn, apiDeleteAsset, apiRequestPwdReset, apiUpdatePwd, apiGetDashboardData,  apiGetAuthTokenForUser, imgUrlPrefix };
