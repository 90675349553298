import React from "react";
import Sidebar from "../Sidebar";
import "../../../App.css";
import CodeSnippet from "../../Prism";
import BrowserSetup from "../../images/tutorial-browser-setup.png";
import ConsoleSetup from "../../images/tutorial-concept-setup.png";
import "prismjs/components/prism-javascript";
import "prismjs/components/prism-jsx";

const QuickStart = () => {
  const exampleCode = `
  {
    "user-groups": {
      "core-competency": {
        "communication": 0,
      },
      "technical-ability": {
        "JavaScript": 0,
      }
    }
  }`;

  const reactCreateTerminal = `
    npx create-react-app my-app
    cd my-app
  `;

  const createReactComponent = `
  import React, { useState, useEffect } from 'react';
  import './MyAssets.css'; // Import the CSS file

  const MyAssets = () => {
    const [communication, setCommunication] = useState(0);

    const [javaScript, setJavaScript] = useState(0);


    useEffect(() => {
      fetch('/api.json')
        .then(response => response.json())
        .then(data => {
          const coreComp = data["user-groups"]["core-competency"];
          const techAbility = data["user-groups"]["technical-ability"];
          console.log("JSON data:", data)
          setCommunication(coreComp.communication);

          setJavaScript(techAbility.JavaScript);

        })
        .catch(error => console.error('Error fetching data:', error));
    }, []);

    return (
      <div>
        <h1>Portfolio Skills Section</h1>
        <h2>Core Competency</h2>
        <div className="competency-container">
          <div className="box">
            <div className="value">{communication}</div>
            <div className="key">Communication</div>
          </div>
        </div>

        <h2>Technical Ability</h2>
        <div className="ability-container">
          <div className="box">
            <div className="value">{javaScript}</div>
            <div className="key">JavaScript</div>
          </div>
        </div>
      </div>
    );
  };

  export default MyAssets;
  `;

  return (
    <div className="bg-main text-white mt-5">
      <div className="row">
        <Sidebar />
        <div className="col doc-container">
          <div className="col mt-4">
            <h2 className="doc-caps">How to...</h2>
            <h1 className="doc-header">Quick Start</h1>
            <h3 className="doc-sub-header">
              This is a simple explanation of the current objectives.
            </h3>
          </div>

          <div className="px-5">
            <h1 className="doc-sub-header">
              In this section you will learn how to:
            </h1>
            <ol className="footer-bg py-4">
              <li className="doc-body-medium p-1">Learning Task 1</li>
            </ol>

            <div className="snippet-container">
              <h4 className="doc-header">Step 1: Task</h4>
              <p className="doc-sub-header">Short description of task.</p>
              <p className="doc-sub-header">Short desciption</p>

              <CodeSnippet code={createReactComponent} language="jsx" />

              <img
                src={BrowserSetup}
                alt="Browser setup"
                style={{ width: "100%", height: "auto" }}
              />
            </div>

            <div className="snippet-container">
              <h4 className="doc-header">Summary</h4>
              <p className="doc-sub-header">
                This is where a recap of the learning objectives and tasks will
                go.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuickStart;
