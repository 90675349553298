import React from "react";
import Sidebar from "../Sidebar";
import "../../../App.css";
import CodeSnippet from "../../Prism";
import BrowserSetup from "../../images/tutorial-browser-setup.png";
import ConsoleSetup from "../../images/django-directory.png";
import "prismjs/components/prism-javascript";
import "prismjs/components/prism-jsx";

import AssetBuilder from "../../images/asset-builder.png";
import AssetDashboard from "../../images/asset-dashboard.png";
import DjangoDirectory from "../../images/django-directory.png";
import ReactDirectory from "../../images/react-directory.png";
import FinalRender from "../../images/final-render.png";
import DividerLine from "../../images/divider-line.svg";

const DynamicRouting = () => {


  const djangoTerminal = `
    pip install django djangorestframework
    django-admin startproject myproject
    cd myproject
  `;

  const pythonTerminal = `
    python manage.py startapp graphics

`;

  const djangoModel = `
    from django.db import models

    class Graphic(models.Model):
        api_key = models.CharField(max_length=255)
        graphic_data = models.JSONField()
        created_at = models.DateTimeField(auto_now_add=True)

        def __str__(self):
            return self.api_key
  `;

  const createViews = `
    from django.http import JsonResponse, Http404
    from django.views.decorators.csrf import csrf_exempt
    from .models import Graphic
    import json
    from . import jmcauth
    from myproject import settings

    @csrf_exempt
    def create_graphic(request):
        if request.method == "POST":
            try:
                data = json.loads(request.body)
                api_key = data.get('api_key')
                graphic_data = data.get('graphic_data')

                graphic = Graphic(api_key=api_key, graphic_data=graphic_data)
                graphic.save()
                return JsonResponse({"message": "Graphic created successfully."}, status=201)
            except Exception as e:
                return JsonResponse({"error": str(e)}, status=400)

        return JsonResponse({"error": "Invalid request method."}, status=405)

    def get_graphic(request, graphic_id, value):
        try:
            graphic = Graphic.objects.get(id=graphic_id)
            return JsonResponse({"graphic_id": graphic_id, "value": value, "graphic_data": graphic.graphic_data})
        except Graphic.DoesNotExist:
            raise Http404("Graphic not found")

`;

  const createUrls = `
    from django.contrib import admin
    from django.urls import path
    from graphics import views

    urlpatterns = [
        path('admin/', admin.site.urls),
        path('api/create/', views.create_graphic, name='create_graphic'),
        path('api/graphic/<int:graphic_id>/<int:value>/', views.get_graphic, name='get_graphic'),
    ]
  `;

  const configSettings = `
    import os
    from pathlib import Path

    BASE_DIR = Path(__file__).resolve().parent.parent
    SECRET_KEY = 'your-secret-key'
    D99_SECRET_KEY = 'your-d99-secret-key'
    D99_PUBLIC_KEY = 'your-d99-public-key'
    DEBUG = True
    ALLOWED_HOSTS = ["*"]

    INSTALLED_APPS = [
        'django.contrib.admin',
        'django.contrib.auth',
        'django.contrib.contenttypes',
        'django.contrib.sessions',
        'django.contrib.messages',
        'django.contrib.staticfiles',
        'rest_framework',
        'graphics',
        'corsheaders'
    ]

    MIDDLEWARE = [
        'django.middleware.security.SecurityMiddleware',
        'django.contrib.sessions.middleware.SessionMiddleware',
        'corsheaders.middleware.CorsMiddleware',
        'django.middleware.common.CommonMiddleware',
        'django.middleware.csrf.CsrfViewMiddleware',
        'django.contrib.auth.middleware.AuthenticationMiddleware',
        'django.contrib.messages.middleware.MessageMiddleware',
        'django.middleware.clickjacking.XFrameOptionsMiddleware',
    ]

    ROOT_URLCONF = 'myproject.urls'

  `;

  const reactTerminal = `
  npx create-react-app myreactapp
  cd myreactapp
`;

const axiosTerminal = `
  npm install axios
`;
const reactDataDisplay = `
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './App.css';

const DataDisplay = () => {
    const [users, setUsers] = useState([]);

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                let response = await axios.post('https://your-django-url/api/get/imageurl/', { user_name: 'Betty' });
                const bettyData = {
                    name: 'Betty',
                    score: response.data.url.value,
                    image: response.data.url.url
                };

                response = await axios.post('https://your-django-url/api/get/imageurl/', { user_name: 'Billy' });
                const billyData = {
                    name: 'Billy',
                    score: response.data.url.value,
                    image: response.data.url.url
                };

                setUsers([bettyData, billyData]);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchUsers();
    }, []);

    return (
        <div>
            <h1 className="title-text">React/Django MD99 API DEMO</h1>
            <div className="user-container">
                {users.map(user => (
                    <div key={user.name} className="user-box">
                        <img src={user.image} className="user-image" />
                        <h1>{user.name} {user.score}</h1>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default DataDisplay;

`;

const reactApp = `
  import React from 'react';
  import DataDisplay from './DataDisplay';
  import './App.css';

  const App = () => {
      return (
          <div className="App">
              <DataDisplay />
          </div>
      );
  };

  export default App;
`;


  return (
    <div className="bg-main text-white mt-5">
      <div className="row">
        <Sidebar />
        <div className="col doc-container">
          <div className="col mt-4">
            <h2 className="doc-caps">Dynamic Route Construction</h2>
            <h1 className="doc-header">Dynamic Routing Tutorial</h1>
            <h3 className="doc-sub-header">
            Welcome to the MD99 Graphics Engine tutorial! This tutorial is designed to guide both beginner and experienced web developers through the process of building custom data graphics using our MD99 Graphics Engine API.
            </h3>
          </div>

          <div className="px-5">
            <h1 className="doc-body-large">
            We will cover three main phases:
            </h1>
            <ol className="footer-bg py-4">
              <li className="doc-body-medium p-1">Building a Custom Data Graphic on Our Website</li>
              <li className="doc-body-medium p-1">Setting Up a Django Project for Dynamic Route Construction and Authentication</li>
              <li className="doc-body-medium p-1">Building a React Project to Connect to the Django Backend</li>
            </ol>
            <h3 className="doc-body-medium">
            Feel free to use the tools and platforms you are most comfortable with. For demonstration purposes, we will use GitHub for repositories, Gitpod with VS Code's browser extension to build and test the code.
            </h3>

              <img
                src={DividerLine}
                alt="Browser setup"
                style={{ width: "100%", height: "auto" }}
                className="mt-5"
              />


{/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

            <div className="snippet-container mt-5">
              <h4 className="doc-header">Fullstack Project Template</h4>
              <p className="doc-body-small">
              To make your development process smoother, we provide a complete fullstack project template that you can open and use directly. This template includes both the Django backend and the React frontend, fully integrated to demonstrate the functionality of the MD99 Graphics Engine API. You can view and use the entire project or individual files as needed.
              </p>
              
              <p className="doc-body-medium">
              You can access the Django Route Constructor project template by clicking on the link below:
              </p>
              <a href="https://github.com/JMC818386/MD99-Django-Demo" target="_blank">Open Django Template</a>
            </div>

            <div className="snippet-container mt-5">
              <p className="doc-body-medium">
              You can access the React Asset Display project template by clicking on the link below:
              </p>
              <a href="https://github.com/JMC818386/MD99-React-Demo" target="_blank">Open React Template</a>
            </div>

            <img
                src={DividerLine}
                alt="Browser setup"
                style={{ width: "100%", height: "auto" }}
                className="mt-5"
              />

{/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

            <div className="snippet-container  mt-5 pt-3">
              <h4 className="doc-header">Phase 1: Building a Custom Data Graphic</h4>
              <p className="doc-body-medium">Navigate to our website where you can create custom data graphics. Utilize the no-code editor to design and customize your graphic.</p>
              <img
                src={AssetBuilder}
                alt="Browser setup"
                style={{ width: "100%", height: "auto" }}
                className="mt-2mb-5"
              />
              <p className="doc-body-medium">Once created, your graphic will be instantly available through our API.</p>
              
              <h4 className="doc-header mt-5 pt-3">Retrieve Your API Keys</h4>
              <p className="doc-body-medium">Navigate to your My Assets Dashboard and copy your public_key and secret_key.</p>
               <img
                src={AssetDashboard}
                alt="Browser setup"
                style={{ width: "100%", height: "auto" }}
                className="mt-2 mb-5"
              />
              <p className="doc-body-medium m-2 pb-5">You will need to insert these keys into your django project in order to handle authenitcation while making calls to our API.</p>
            </div>

            <img
                src={DividerLine}
                alt="Browser setup"
                style={{ width: "100%", height: "auto" }}
                className="mt-4 mb-5"
              />

{/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

            <div className="snippet-container mt-4">
              <h4 className="doc-header">Phase 2: Setting Up a Django Project</h4>
              <p className="doc-sub-header">Create a Django Project</p>
              <p className="doc-body-large pt-3">Set Up Django Environment</p>
              <CodeSnippet code={djangoTerminal} language="python" />
              <p className="doc-body-small mt-2">We start by installing Django and Django REST Framework. The django-admin startproject myproject command initializes a new Django project named myproject, and cd myproject navigates into the project directory.</p>

              <p className="doc-body-large">Create an App</p>
              <CodeSnippet code={pythonTerminal} language="python" />
              <p className="doc-body-small mt-2">The startapp graphics command creates a new Django app called graphics. Apps are modular components within a Django project that handle specific functionality.</p>

              <img
                src={DjangoDirectory}
                alt="Browser setup"
                style={{ width: "35%", height: "auto" }}
                className="mt-4 mb-3"
              />

              <p className="doc-body-large">Define Models</p>
              <CodeSnippet code={djangoModel} language="python" />
              <p className="doc-body-small mt-2">This code defines a Graphic model, which represents a data graphic in our database. The api_key field stores the user's API key, graphic_data holds the graphic's data in JSON format, and created_at records when the graphic was created. The __str__ method returns a string representation of the object, useful for debugging and display purposes.</p>

              <p className="doc-body-large">Create Views</p>
              <CodeSnippet code={createViews} language="python" />
              <p className="doc-body-small mt-2">These views handle HTTP requests related to graphics. create_graphic processes POST requests to create a new graphic, parsing the JSON data and saving it to the database. get_graphic retrieves a graphic by its ID and dynamically renders it based on a value parameter. The @csrf_exempt decorator is used to disable CSRF protection for the create view, necessary for API endpoints handling JSON requests.</p>

              <p className="doc-body-large">Set Up URLs</p>
              <CodeSnippet code={createUrls} language="python" />
              <p className="doc-body-small mt-2">This code maps URLs to the corresponding views. The urlpatterns list routes incoming requests to the create_graphic and get_graphic views based on the URL patterns.</p>

              <p className="doc-body-large">Configure Settings</p>
              <CodeSnippet code={configSettings} language="python" />
              <p className="doc-body-small mt-2">This section of the settings file configures the Django project. It sets up the base directory, secret keys, allowed hosts, installed apps, and middleware. The INSTALLED_APPS list includes the graphics app and other essential Django and third-party apps. Middleware components handle various aspects of request and response processing.</p>

              {/* <img
                src={BrowserSetup}
                alt="Browser setup"
                style={{ width: "100%", height: "auto" }}
              /> */}
            </div>

            <img
              src={DividerLine}
              alt="Browser setup"
              style={{ width: "100%", height: "auto" }}
              className="mt-5 mb-4"
            />
{/* 
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

            <div className="snippet-container mt-5">
              <h4 className="doc-header mt-5">Phase 3: Building a React Project</h4>
              <p className="doc-sub-header">Create a React Projectt</p>
              <p className="doc-body-large pt-3">Set Up React Environment</p>
              <CodeSnippet code={reactTerminal} language="jsx" />
              <p className="doc-body-small mt-2">The npx create-react-app myreactapp command creates a new React project named myreactapp, and cd myreactapp navigates into the project directory. Create React App sets up a modern React build environment with no configuration.</p>

              <p className="doc-body-large pt-3">Install Axios</p>
              <CodeSnippet code={axiosTerminal} language="jsx" />
              <p className="doc-body-small mt-2">Axios is a promise-based HTTP client for making requests to the Django backend. The npm install axios command installs Axios in the React project.</p>

              <p className="doc-body-large pt-3">Create a Data Display Component</p>
              <CodeSnippet code={reactDataDisplay} language="jsx" />
              <p className="doc-body-small mt-2">This React component, DataDisplay, fetches and displays user data from the Django backend. It uses the useState and useEffect hooks to manage state and side effects. The fetchUsers function makes HTTP POST requests to the API to retrieve data for users "Betty" and "Billy". The data is stored in the users state and displayed in the component.</p>

              <img
                src={ReactDirectory}
                alt="Browser setup"
                style={{ width: "35%", height: "auto" }}
                className="mt-5 mb-3"
              />

              <p className="doc-body-large pt-3"> Integrate Component into App</p>
              <CodeSnippet code={reactApp} language="jsx" />
              <p className="doc-body-small mt-2">The App component imports and renders the DataDisplay component. This sets up the main application structure, integrating the data display functionality into the overall app. The App.css file is used for styling the components.</p>

              <img
                src={FinalRender}
                alt="Browser setup"
                style={{ width: "100%", height: "auto" }}
                className="mt-5"
              />
            </div>

{/* //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

            <div className="snippet-container mt-5">
              <h4 className="doc-header">Summary</h4>
              <p className="doc-sub-header">
              Congratulations! You have successfully built a custom data graphic using our MD99 Graphics Engine API, set up a Django project for dynamic route construction and authentication, and created a React project to connect to the Django backend. Feel free to use the provided templates to start building your own projects and explore the many ways you can use our graphics engine to enhance your web applications. Happy coding!
              </p>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default DynamicRouting;
