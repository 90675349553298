import { apiGetAuthTokenForUser, imgUrlPrefix } from './ApiClient';

export function authTokenBuildUrl (assetName, value, callback) {
    assetName = md99_stripAssetName (assetName);
    // console.log("assetName:", assetName)

	const storedToken = md99_getStoredToken();
	if (storedToken != null) {
		// console.log("Found AuthToken in Cache")
		const clientID = localStorage.getItem("auth_client");

		const fullUrl =  md99_buildFullUrl(clientID, storedToken, value, assetName)
        callback(fullUrl);
		return;
	}
		
	apiGetAuthTokenForUser (function (data) {
		const token     = data.token;
		const clientID  = data.public_key;
        localStorage.setItem("auth_token", data.token);
        localStorage.setItem("auth_expire", data.expires);
        localStorage.setItem("auth_client", data.public_key);
		
		const fullUrl =  md99_buildFullUrl(clientID, token, value, assetName)
        callback(fullUrl);
	});
	// return "";
}
// export function authTokenGetAuthToken () {
// 	apiGetAuthTokenForUser (function (data) {
// 		return data.token;
// 	});
// }
  
function md99_buildFullUrl(clientID, token, value, assetName) {
    const urlPrefix = imgUrlPrefix();
    const urlSuffix = "get/" + clientID + "/" + token + "/" + value + "/" + assetName + ".png";
	console.log("urlPrefix + urlSuffix", urlPrefix + urlSuffix);
    return urlPrefix + urlSuffix;
}


function md99_stripAssetName(name) {
	name = name.replaceAll (" " , "-");				// replace spaces with dashes
	name = name.toLowerCase();							// change to all lower case
	name = name.replace (/[^-a-z0-9_]/g, "");		// keep only dash, underscore, letters and numbers
	name = name.replace (/\-+/g, '-');				// remove duplicate dashes
	name = name.replace (/^\-+|\-+$/g, "");    	// trim leading and trailing dashes
   return name;
}

function md99_getStoredToken() {
	const expire = localStorage.getItem("auth_expire");
	if (! expire) {
		return null;
	}

	const token = localStorage.getItem("auth_token");
	if (! token) {
		return null;
	}
	
	const client = localStorage.getItem("auth_client");
	if (! client) {
		return null;
	}

	const now = Math.floor (Date.now() / 1000);
	if (now >= expire) {
		return null;
	}
	return token;
}