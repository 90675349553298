export function phpSessGet() {
	let sessID = localStorage.getItem("sessID");
	if (!sessID) {
	  sessID = "dummy";
	  phpSessSet(sessID);
	}
	return sessID;
  }
  
  export function phpSessSet(sessID) {
	localStorage.setItem("sessID", sessID);
  }
  
  export function phpSessLogout() {
	localStorage.setItem("sessID", "dummy");
  }
  
  export function phpSessIsLoggedIn() {
	const sessID = phpSessGet();
	return sessID !== "dummy";
  }

  export function phpSessNameSet(first_name, last_name) {
	localStorage.setItem("first_name", first_name);
	localStorage.setItem("last_name", last_name);
  }

  export function phpSessFirstNameGet() {
	let first_name = localStorage.getItem("first_name");
	if (!first_name) {
	  return "unknown";
	}
	return first_name;
  }
  
  export function phpSessLastNameGet() {
	let last_name = localStorage.getItem("last_name");
	if (!last_name) {
	  return "unknown";
	}
	return last_name;
  }

  export function phpSessFullNameGet() {
	let first_name = localStorage.getItem("first_name");
	let last_name = localStorage.getItem("last_name");
	if (!first_name) {
	  first_name = "";
	}else {
		first_name += " "; 
	}
	if (!last_name) {
		last_name = "unknown";
	  }
	return first_name + " " + last_name;
  }