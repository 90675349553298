import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";
import "react-responsive-modal/styles.css";
import '@fortawesome/fontawesome-free/css/all.min.css';

import { useNavigate, useLocation } from 'react-router-dom';
import { Modal } from "react-responsive-modal"; // Import the Modal component
import { phpSessIsLoggedIn } from './PhpSession';

import Login from "./Login";
import ConfirmAccount from "./ConfirmAccount";
import ResetPassword from "./ResetPassword";
import ConfirmCode from "./ConfirmCode";
import SignUp from "./SignUp";
import Profile from "./Profile";

import Hero1 from "./images/hero-image-1.png";
import Hero2 from "./images/hero-image-2.png";
import Hero3 from "./images/hero-image-3.png";
import MD99Assets from "./images/md99-assets.png";
import Dashboard from "./images/dashboard-hero-img.png";
import Builder from "./images/builder.png";
import DividerLine from './images/divider-line.svg'; // Adjust the path as necessary

const Hero = () => {
  const location = useLocation();
  const [currentComponent, setCurrentComponent] = useState("Login");
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [openModal, setOpenModal] = useState(false); // State to handle modal visibility
  const navigate = useNavigate();

  useEffect(() => {
    const checkLoginStatus = async () => {
      const loggedIn = await phpSessIsLoggedIn();
      setUserLoggedIn(loggedIn);
    };

    checkLoginStatus();
  }, []);

  // Re-render the component every time the location changes
  useEffect(() => {
    setCurrentComponent("Login");
    const checkLoginStatus = async () => {
      const loggedIn = await phpSessIsLoggedIn();
      setUserLoggedIn(loggedIn);
    };

    checkLoginStatus();

    if (location.state?.showSignup) {
      setCurrentComponent("SignUp");
    }
  }, [location]);

  const navigateToComponent = (componentName) => {
    setCurrentComponent(componentName);
  };

  const handleLoginSuccess = () => {
    setUserLoggedIn(true);
    navigate("/dashboard");
  };

  const renderComponent = () => {
    if (!userLoggedIn) {
      switch (currentComponent) {
        case "Login":
          return <Login navigate={navigateToComponent} handleLoginSuccess={handleLoginSuccess} />;
        case "ConfirmAccount":
          return <ConfirmAccount navigate={navigateToComponent} />;
        // case "ConfirmCode":
        //   return <ConfirmCode navigate={navigateToComponent} />;
        // case "ResetPassword":
        //   return <ResetPassword navigate={navigateToComponent} />;
        case "SignUp":
          return <SignUp navigate={navigateToComponent} />;
        default:
          return <Login navigate={navigateToComponent} handleLoginSuccess={handleLoginSuccess} />;
      }
    } else {
      return null;
    }
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <div className="hero-container d-flex flex-column mt-3">
      <div className="container mt-5">
        <div className="row mb-5">
          <div className="col-lg-6 col-sm-12 d-flex flex-column align-items-start">
            <p className="hero-title-accent">Web Data Graphics Engine v0.1</p>
            <div className="hero-row mb-3">
              <h1 className="hero-title m-0 p-0">Data</h1>
              <h1 className="hero-title m-0 p-0">Graphics</h1>
              <h1 className="hero-title mb-1">Simplified</h1>
              <p className="hero-body mt-3">
                Elevate your message with cutting-edge data visualization.
              </p>
            </div>
            <div className="button-row mb-2">
              <button
                className="btn hero-btn hero-doc-btn p-3 mt-1"
                onClick={handleOpenModal} // Open modal on button click
              >
                <i className="fas fa-play-circle mr-2"></i> MD99 DGE API
              </button>
              <button
                className="btn hero-btn hero-pricing-btn p-3 mt-1"
                onClick={() => navigate('/documentation/dynamic_routing')}
              >
                Learn More
              </button>
            </div>
          </div>
          <div className="col-md-6 d-flex justify-content-center align-items-center">
            {renderComponent()}
            {userLoggedIn && (
              <img src={Hero3} alt="Color Logo Icon" className="hero-image mb-5" />
            )}
          </div>
        </div>

        <div className="row">
          <img
            src={DividerLine}
            alt="Browser setup"
            style={{ width: "100%", height: "auto" }}
            className="mb-5 mt-5"
          />
          <div className="col-md-4 d-flex justify-content-center align-items-center">
            <img src={MD99Assets} alt="MD99 Assets" className="assets-image" />
          </div>
          <div className="col-md-4 d-flex justify-content-center align-items-center">
            <img src={Builder} alt="Builder" className="dashboard-image" />
          </div>
          <div className="col-md-4 d-flex justify-content-center align-items-center">
            <img src={Dashboard} alt="Dashboard" className="dashboard-image" />
          </div>
        </div>

        <div className="row">
          <div className="col-md-4 d-flex flex-column align-items-start">
            <h1 className="hero-title-switch m-0 p-0 mb-2">MD99 Assets</h1>
            <p className="hero-body-switch">
              Our dynamic web data graphics empower you to create visually stunning data representations effortlessly. Build customized assets on our website and retrieve them via our API.
            </p>
          </div>
          <div className="col-md-4 d-flex flex-column align-items-start">
            <h1 className="hero-title-switch m-0 p-0 mb-2">Asset Builder</h1>
            <p className="hero-body-switch">
              Unleash your creativity with our Asset Builder! Choose from blank or pre-set templates and customize your graphics to perfection.
            </p>
          </div>
          <div className="col-md-4 d-flex flex-column align-items-start">
            <h1 className="hero-title-switch m-0 p-0 mb-2">Asset Manager</h1>
            <p className="hero-body-switch">
              Store all your created assets in one place and easily manage them. Update values and parameters manually to reuse designs across multiple projects.
            </p>
          </div>
          <img
            src={DividerLine}
            alt="Browser setup"
            style={{ width: "100%", height: "auto" }}
            className="mt-5"
          />
        </div>
      </div>

      <Modal open={openModal} onClose={handleCloseModal} className="react-responsive-modal" center>
        <div>
          <h2 className="pricing-text">Introduction Video Coming Soon</h2>
        </div>
        
      </Modal>
    </div>
  );
};

export default Hero;
