import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../../App.css";
import { FaChevronDown, FaChevronRight } from "react-icons/fa"; // Importing arrow icons from react-icons

const Sidebar = () => {
  const [openSections, setOpenSections] = useState({
    "Quick Start": true,
    "MD99 Basics": true,
    "Tutorials": true,
    "Lifecycle": true,
  });

  const toggleSection = (sectionName) => {
    setOpenSections((prevOpenSections) => ({
      ...prevOpenSections,
      [sectionName]: !prevOpenSections[sectionName],
    }));
  };

  return (
    <div className="col-3 sidebar open">
      <div className="div">
      <div>
        <button
          className="section-btn side-bar-header"
          onClick={() => toggleSection("Quick Start")}
        >
          {openSections["Quick Start"] ? <FaChevronDown className="arrow-icon mr-3" /> : <FaChevronRight className="arrow-icon mr-3" />}
          Getting Started
        </button>
        <div className={`sub-nav side-bar-body ${openSections["Quick Start"] ? "open" : ""}`}>
          <Link to="/documentation/introduction" className="sub-nav-link custom-link">Introduction</Link>
          <Link to="/documentation/quick_start" className="sub-nav-link custom-link">Quick Start</Link>
        </div>
      </div>

      <div>
        <button
          className="section-btn side-bar-header"
          onClick={() => toggleSection("MD99 Basics")}
        >
          {openSections["MD99 Basics"] ? <FaChevronDown className="arrow-icon mr-3" /> : <FaChevronRight className="arrow-icon mr-3" />}
          MD99 Basics
        </button>
        <div className={`sub-nav side-bar-body ${openSections["MD99 Basics"] ? "open" : ""}`}>
          <Link to="/documentation/onboarding" className="sub-nav-link custom-link">Onboarding</Link>
          <Link to="/documentation/interface" className="sub-nav-link custom-link">Tour the Interface</Link>
          <Link to="/documentation/first_asset" className="sub-nav-link custom-link">Design Your First Asset</Link>
          <Link to="/documentation/ways_to_use" className="sub-nav-link custom-link">Ways to use the MD99 API</Link>
        </div>
      </div>

      <div>
        <button
          className="section-btn side-bar-header"
          onClick={() => toggleSection("Tutorials")}
        >
          {openSections["Tutorials"] ? <FaChevronDown className="arrow-icon mr-3" /> : <FaChevronRight className="arrow-icon mr-3" />}
          Tutorials
        </button>
        <div className={`sub-nav side-bar-body ${openSections["Tutorials"] ? "open" : ""}`}>
          <Link to="/documentation/api_beginners" className="sub-nav-link custom-link">API Beginners</Link>
          <Link to="/documentation/dynamic_routing" className="sub-nav-link custom-link">Dynamic Routing</Link>
          <Link to="/documentation/database" className="sub-nav-link custom-link">Database Integration</Link>
        </div>
      </div>
      <div>
        <button
          className="section-btn side-bar-header"
          onClick={() => toggleSection("Lifecycle")}
        >
          {openSections["Lifecycle"] ? <FaChevronDown className="arrow-icon mr-3" /> : <FaChevronRight className="arrow-icon mr-3" />}
          API Specs
        </button>
        <div className={`sub-nav side-bar-body ${openSections["Lifecycle"] ? "open" : ""}`}>
          <Link to="/documentation/overview" className="sub-nav-link custom-link">Overview</Link>
          <Link to="/documentation/calling_api" className="sub-nav-link custom-link">Connecting to the MD99 API</Link>
          <Link to="/documentation/query_types" className="sub-nav-link custom-link">Query Types</Link>
          <Link to="/documentation/error_handling" className="sub-nav-link custom-link">Error Types</Link>
        </div>
      </div>
      </div>
    </div>
  );
};

export default Sidebar;
