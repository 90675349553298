import React, { useState, useRef } from "react";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Google from "./images/Google.svg";
import Apple from "./images/Apple.svg";
import Github from "./images/Github.svg";
import Windows from "./images/Windows.svg";
import Facebook from "./images/Facebook.svg";
import DividerLine from "./images/divider-line.svg";
import BackBtn from "./images/back-btn.svg";
import toast, { Toaster } from "react-hot-toast";
import { motion } from "framer-motion";

function ConfirmCode({ navigate }) {
  const [confirmationCode1, setConfirmationCode1] = useState("");
  const [confirmationCode2, setConfirmationCode2] = useState("");
  const [confirmationCode3, setConfirmationCode3] = useState("");
  const [confirmationCode4, setConfirmationCode4] = useState("");
  const [confirmationCode5, setConfirmationCode5] = useState("");
  const [confirmationCode6, setConfirmationCode6] = useState("");

  const input1 = useRef(null);
  const input2 = useRef(null);
  const input3 = useRef(null);
  const input4 = useRef(null);
  const input5 = useRef(null);
  const input6 = useRef(null);

  const handleSubmit = () => {
    // Validate the code here
    toast.success('6-digit Pass-Code Authenticated 👍', {
      duration: 4000,
      position: 'top-right',
      style: {
        border: '1px solid #28a745',
        padding: '16px',
        color: '#fff',
        background: '#28a745',
      },
      icon: '✔️',
    });
    navigate("ResetPassword");
  };

  const handleInputChange = (event, setState, nextInputRef) => {
    const { value } = event.target;
    if (value.match(/[0-9]/) || value === "") {
      setState(value);
      if (value && nextInputRef) {
        nextInputRef.current && nextInputRef.current.focus();
      }
    }
  };

  const handleKeyDown = (event, previousInputRef) => {
    if (event.key === "Backspace" && !event.target.value) {
      previousInputRef.current && previousInputRef.current.focus();
    }
  };

  return (
    <motion.div
    className="box"
    initial={{ opacity: 0, scale: 0.95 }}
    animate={{ opacity: 1, scale: 1 }}
    transition={{
      duration: .5,
      ease: "easeOut",
      scale: {
        type: "spring",
        damping: 10,
        stiffness: 50,
        restDelta: 0.001
      }
    }}
  >
    <div className="login-container">
        
      <div className="welcome-wrapper d-flex flex-row justify-content-center align-items-center">
        <img
          className="clickable  back-arrow-btn"
          loading="lazy"
          alt="back-btn"
          src={BackBtn}
          onClick={() => navigate("ConfirmAccount")}
        />
        <h1 className="d-flex justify-content-center pricing-title pt-3 py-3">
          Confirm Code
        </h1>
      </div>

      <div className="d-flex justify-content-center">
        <p className="doc-body-medium">
          Enter the 6-digit Authentication Code that we sent to your Email:
        </p>
      </div>
      <section className="frame-parent mb-2">
        <div className="frame-group">
          <div className="input-wrapper d-flex row mb-4 mt-3 mx-4">
            <label
              htmlFor="confirmationCodeInput"
              className="form-label doc-body-small text-secondary d-flex justify-content-start"
            >
              Enter your 6-digit Code
            </label>
            <div className="container-fluid row d-flex justify-content-around">

                <input
                type="text"
                id="confirmationCode1"
                name="confirmationCode1"
                className="input-field-code"
                value={confirmationCode1}
                onChange={(e) => handleInputChange(e, setConfirmationCode1, input2)}
                onKeyDown={(e) => handleKeyDown(e, input1)} // Correctly reference the previous input
                ref={input1}
                maxLength="1"
                />


                <input
                type="text"
                id="confirmationCode2"
                name="confirmationCode2"
                className="input-field-code"
                value={confirmationCode2}
                onChange={(e) => handleInputChange(e, setConfirmationCode2, input3)}
                onKeyDown={(e) => handleKeyDown(e, input1)} // Correctly reference the previous input
                ref={input2}
                maxLength="1"
                />

                <input
                type="text"
                id="confirmationCode3"
                name="confirmationCode3"
                className="input-field-code"
                value={confirmationCode3}
                onChange={(e) => handleInputChange(e, setConfirmationCode3, input4)}
                onKeyDown={(e) => handleKeyDown(e, input2)} // Correctly reference the previous input
                ref={input3}
                maxLength="1"
                />

              <input
                type="text"
                id="confirmationCode4"
                name="confirmationCode4"
                className="input-field-code"
                value={confirmationCode4}
                onChange={(e) =>
                  handleInputChange(e, setConfirmationCode4, input5)
                }
                onKeyDown={(e) => handleKeyDown(e, input3)}
                ref={input4}
                maxLength="1"
              />

            <input
                type="text"
                id="confirmationCode5"
                name="confirmationCode5"
                className="input-field-code"
                value={confirmationCode5}
                onChange={(e) => handleInputChange(e, setConfirmationCode5, input6)}
                onKeyDown={(e) => handleKeyDown(e, input4)} // Correctly reference the previous input
                ref={input5}
                maxLength="1"
            />

            <input
                type="text"
                id="confirmationCode6"
                name="confirmationCode6"
                className="input-field-code"
                value={confirmationCode6}
                onChange={(e) => handleInputChange(e, setConfirmationCode6)}
                onKeyDown={(e) => handleKeyDown(e, input5)} // Correctly reference the previous input
                ref={input6}
                maxLength="1"
            />

            </div>
          </div>
        </div>
        <div className="group-frame d-flex justify-content-center flex-row">
          <div className="rectangle-parent">
            <div className="frame-inner" />
            <button className="sign-up render-btn px-5 py-2" onClick={() => navigate("Login")}>Cancel</button>
          </div>
          <div className="rectangle-group">
            <div className="rectangle-div" />
            <button className="login1 confirm-btn px-5 py-2 mb-2" onClick={handleSubmit}>Submit</button>
          </div>
        </div>
      </section>
      {/* <div className="login-inner d-flex justify-content-center">
        <img className="vector-icon" loading="lazy" alt="" src={DividerLine} />
      </div>
      <div className="login-child mt-3">
        <div className="frame-div">
          <div className="sign-in-with-wrapper">
            <p className="d-flex justify-content-center toolbar-text">
              Sign in with
            </p>
          </div>

          <div className="d-flex justify-content-center align-items-center">
            <a href="https://www.google.com" target="_blank" rel="noopener noreferrer">
              <img className="p-1 m-2" loading="lazy" alt="Google" src={Google} />
            </a>
            <a href="https://www.apple.com" target="_blank" rel="noopener noreferrer">
              <img className="p-1 m-2" loading="lazy" alt="Apple" src={Apple} />
            </a>
            <a href="https://www.github.com" target="_blank" rel="noopener noreferrer">
              <img className="p-1 m-2" loading="lazy" alt="Github" src={Github} />
            </a>
            <a href="https://www.microsoft.com" target="_blank" rel="noopener noreferrer">
              <img className="p-1 m-2" loading="lazy" alt="Windows" src={Windows} />
            </a>
            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
              <img className="p-1 m-2" loading="lazy" alt="Facebook" src={Facebook} />
            </a>
          </div>
        </div>
      </div> */}
    </div>
    </motion.div>
  );
};

export default ConfirmCode;
