

  import "../App.css";
  import "bootstrap/dist/css/bootstrap.min.css";
  import React, { useEffect, useState, useRef } from "react";
  import { CFormSwitch, CTooltip } from "@coreui/react";
  import { useParams, useLocation } from "react-router-dom";
  import axios from "axios";
  import { apiGetOneAsset, apiSaveOneAsset } from "../Components/ApiClient";
  import { phpSessGet, phpSessSet, phpSessLogout, phpSessIsLoggedIn } from '../Components/PhpSession.js'
  import toast from "react-hot-toast";
  import { motion } from "framer-motion";
  import BackBtn from "../Components/images/back-btn.svg";
  import { useNavigate } from "react-router-dom";
  
  import BarThicknessLarge from "../Components/images/bar-thickness-large.svg";
  import BarThicknessMedium from "../Components/images/bar-thickness-medium.svg";
  import BarThicknessSmall from "../Components/images/bar-thickness-small.svg";
  import TpBg from "../Components/images/tp-bg.svg";
  import AddBtn from "../Components/images/add-btn.svg";
  import MinusBtn from "../Components/images/minus-btn.svg";
  import QuestionIcon from "../Components/images/question-icon.svg";
  import HistoryBack from "../Components/images/history-back-icon.svg";
  import HistoryForward from "../Components/images/history-forward-icon.svg";
  import SaveAsset from "../Components/images/save-asset-icon.svg";
  import StageAsset from "../Components/images/stage-asset-icon.svg";
  import Edit from "../Components/images/edit.png";
  
  
  const Bar = () => {
    const all_params = useParams();
    const isMounted = useRef(false);
    const [assetInputName, setAssetInputName] = useState("");
    const location = useLocation();
    const { assetName, sessID } = location.state || {};
    const navigate = useNavigate();
  
    // <---------------------State variable declarations----------------------------->
    const [username, setUsername] = useState("");
    const [size, setSize] = useState("");
    const [outerBarActiveBtn, setOuterBarActiveBtn] = useState("");
    const [innerBarActiveBtn, setInnerBarActiveBtn] = useState("");
    const [centerFillActiveBtn, setCenterFillActiveBtn] = useState("");
    const [outerBarColor, setOuterBarColor] = useState("#3d3d42");
    const [innerBarColor, setInnerBarColor] = useState("#3d3d42");
    const [innerBarOffColor, setInnerBarOffColor] = useState("#3d3d42");
    const [shadowColor, setShadowColor] = useState("#29292D");
    const [centerFillColor, setCenterFillColor] = useState("#3D3D42");
    const [radioValue, setRadioValue] = useState("off");
    const [selectedBarThickness, setSelectedBarThickness] = useState("l");
    const [activeButton, setActiveButton] = useState(null);
    const [dynamicImgUrl, setDynamicImgUrl] = useState("");
    const [backgroundStyle, setBackgroundStyle] = useState({
      backgroundColor: "white",
      backgroundImage: "",
    })

    const [zone1MaxInput, setzone1MaxInput] = useState("");
    const [zone2MaxInput, setzone2MaxInput] = useState("");
    const [zone3MaxInput, setzone3MaxInput] = useState("");
    const [zone4MaxInput, setzoneMaxInput] = useState("");
    const [colorZone1Selection, setColorZone1Selection] = useState("#2fdf0c");
    const [colorZone2Selection, setColorZone2Selection] = useState("#f0f410");
    const [colorZone3Selection, setColorZone3Selection] = useState("#f07400");
    const [colorZone4Selection, setColorZone4Selection] = useState("#f00000");
    const [isEditing, setIsEditing] = useState(false);

  
    // <-----------------Dynamic Color---------------------->
    const [dynamicColorActive, setDynamicColorActive] = useState(false);
    
    const [zones, setZones] = useState([
      { name: "Zone 1", v: "", c: "#2fdf0c", in: "0" },
    ]);
  
    const addZone = () => {
      if (zones.length < 5) {
        const newZoneNumber = zones.length + 1;
        setZones([...zones, { name: `Zone ${newZoneNumber}`, v: "", c: "#2fdf0c", in: "0" }]);
      }
    };
  
    const deleteZone = (index) => {
      const updatedZones = zones.filter((_, i) => i !== index);
      setZones(updatedZones);
    };
  
    const handleZoneChange = (index, field, value) => {
      const updatedZones = zones.map((zone, i) =>
        i === index ? { ...zone, [field]: value } : zone
      );
      setZones(updatedZones);
      // console.log(zones)
    };
  
    //<-------------Axios call to retrieve user's last design--------------------->
    const retrieveUserLastDesign = () => {

        apiGetOneAsset(all_params.asset_id, function (data) {

              const params = JSON.parse(data.params);
              setDynamicImgUrl(data.image);
              setSize(params.full_w);
              setSelectedBarThickness(params.block_w);
              setShadowColor(params.shadow_c);
              setOuterBarColor(params.border_c);
              setInnerBarColor(params.on_c);
              setInnerBarOffColor(params.off_c);

              // Parse and set zones data
              var ranges = [];
              if (params.hasOwnProperty('ranges')) {
                ranges = params.ranges;
              }
              // const parsedZones = params.ranges.map((range, index) => ({
              
              const parsedZones = ranges.map((range, index) => ({
                name: `Zone ${index + 1}`,
                v: range.v.toString(),
                c: range.c,
                in: "0",
              }));

              setZones(parsedZones);             
      });
    };
  
    useEffect(() => {
      if (sessID) {
        phpSessSet(sessID); // Ensure the session ID is set
      }
      setAssetInputName(assetName);
      retrieveUserLastDesign();
    }, [sessID, assetName]);
  
    // <----------Bar Thickness button conversion to JSON readable stBar value----------->
    const activeSelectedButton = selectedBarThickness
      ? selectedBarThickness === "l"
        ? "largeButton"
        : selectedBarThickness === "m"
        ? "mediumButton"
        : "smallButton"
      : "";
  
    //<-------Event handler to trigger axios call when Render Component is clicked-------->
  
    useEffect(() => {
      if (isMounted.current) {
        if (
          outerBarColor !== "#3d3d42" ||
            innerBarColor !== "#3d3d42" ||
            innerBarOffColor !== "#3d3d42" ||
            shadowColor !== "#3d3d42" ||
            centerFillColor !== "#3d3d42" ||
            selectedBarThickness !== "xl" ||
            zones.some(zone => zone.color !== "#2fdf0c" || zone.value !== "")
          ) {
          newRenderComponentClick();
        }
      } else {
        isMounted.current = true;
      }
    }, [
      outerBarColor, 
      innerBarColor, 
      innerBarOffColor, 
      shadowColor, 
      centerFillColor, 
      selectedBarThickness,
      zones,
    ]);
  
    const renderComponentClick = () => {};
  
    const newRenderComponentClick = () => {

      const post_data = {
        asset_id: all_params.asset_id,
        full_w: size,
        block_w: selectedBarThickness,
        border_c: outerBarColor,
        shadow_c: shadowColor,
        on_c: innerBarColor,
        off_c: innerBarOffColor,
        ranges: zones,
      };
    
      apiSaveOneAsset(post_data, function (data) {
        setDynamicImgUrl(data.image);
      });
    };
    
  
    // Handler for button clicks in each section
    const handleButtonClick = (section, button) => {
      switch (section) {
        case "outer":
          setOuterBarActiveBtn(button);
          break;
        case "inner":
          setInnerBarActiveBtn(button);
          break;
        case "center":
          setCenterFillActiveBtn(button);
          break;
        default:
          break;
      }
    };
  
    // Function to get button classes for each section
    const getButtonClasses = (section, button) => {
      let isActive = false;
      switch (section) {
        case "outer":
          isActive = outerBarActiveBtn === button;
          break;
        case "inner":
          isActive = innerBarActiveBtn === button;
          break;
        case "center":
          isActive = centerFillActiveBtn === button;
          break;
        default:
          break;
      }
      return `mx-1 size-selector px-3 ${isActive ? "active-button" : ""}`;
    };
  
    // <------------------------------------------Event Handlers------------------------------------------>
    const handleMainRadioChange = (event) => {
      const value = event.target.value;
      setDynamicColorActive(value === "on"); // Update based on the selection
    };
  
    const handleColorChange = (colorSetter, event) => {
      colorSetter(event.target.value);
    };
  
    const handleInputChange = (event) => {
      setSize(event.target.value);
    };
  
    const handleAssetInputNameChange = (event) => {
      setAssetInputName(event.target.value);
    };
  
    const handleUsernameInputChange = (e) => {
      setUsername(e.target.value);
    };
  
    const handleThicknessButtonClick = (buttonId) => {
      setActiveButton(buttonId);
      let thicknessLetter = "";
      if (buttonId === "largeButton") {
        thicknessLetter = "l";
      } else if (buttonId === "mediumButton") {
        thicknessLetter = "m";
      } else if (buttonId === "smallButton") {
        thicknessLetter = "s";
      }
      setSelectedBarThickness(thicknessLetter);
    };
  
    const handleWhiteBackground = () => {
      setBackgroundStyle({
        backgroundColor: "white",
        backgroundImage: "",
      });
    };
  
    const handleBlackBackground = () => {
      setBackgroundStyle({
        backgroundColor: "black",
        backgroundImage: "",
      });
    };
  
    const handleTransparentBackground = () => {
      setBackgroundStyle({
        backgroundColor: "transparent",
        backgroundImage: `url(${TpBg})`, // Use the imported TpBg image here
        backgroundSize: "cover", // Ensure the background covers the container
      });
    };
  
    const handleShadowBackground = () => {
      setBackgroundStyle({
        backgroundColor: shadowColor,
        backgroundImage: "", // Use the imported TpBg image here
      });
    };
  
    useEffect(() => {
      handleShadowBackground();
    }, [shadowColor]);
  
    const resetState = () => {
      setSize("");
      setOuterBarActiveBtn("");
      setInnerBarActiveBtn("");
      setCenterFillActiveBtn("");
      setOuterBarColor("#3d3d42");
      setInnerBarColor("#3d3d42");
      setInnerBarOffColor("#3D3D42");
      setCenterFillColor("#3D3D42");
      setShadowColor("#29292D");
      setZones([
        { name: "Zone 1", value: "", color: "#2fdf0c" },
      ]);
    };

    const handleEditClick = () => {
      setIsEditing(true);
    };
  
    const handleCancelClick = () => {
      setIsEditing(false);
      setAssetInputName(assetName);
    };
  
    const handleConfirmClick = () => {
      // Placeholder for future server-side logic
      toast.success('Asset Name Updated!');
      setIsEditing(false);
    };
  
    return (
      <motion.div
          className="box"
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{
            duration: 1.5,
            ease: "easeOut",
            scale: {
              type: "spring",
              damping: 10,
              stiffness: 50,
              restDelta: 0.001
            }
          }}
        >
  
      <div className="container-fluid bg-main pb-5 pt-5">
        <div className="row d-flex justify-content-center mt-5 ">
          {/* Left half for the image */}
          <div className="col order-last">
            <div className="d-flex flex-column flex-lg-row m-0 p-0">
              <div className="col-4 gradient-bar-container ml-2 mb-2">

                <div className="col-12">

                <div className="col-12 p-0 position-relative d-flex justify-content-center">
                  <div className="d-flex align-items-center justify-content-center my-3" style={{ width: '100%' }}>
                    <img
                      className="clickable back-arrow-btn"
                      loading="lazy"
                      alt="back-btn"
                      src={BackBtn}
                      onClick={() => navigate("/dashboard")}
                      style={{ marginRight: '8px' }}
                    />
                    <input
                      type="text"
                      id="assetNameInput"
                      name="assetName"
                      className={`form-control flex-grow-1 asset-name-field ${!isEditing ? 'no-pointer-events' : ''}`}
                      value={assetInputName}
                      onChange={handleAssetInputNameChange}
                      placeholder="my-asset-name"
                    />
                    {isEditing ? (
                      <>
                        <button onClick={handleCancelClick} className="btn text-danger name-change ml-2">✕</button>
                        <button onClick={handleConfirmClick} className="btn text-success name-change ml-2">✓</button>
                      </>
                    ) : (
                      <img
                        className="clickable edit-asset-name-btn ml-2"
                        loading="lazy"
                        alt="Edit Asset Name"
                        src={Edit}
                        onClick={handleEditClick}
                      />
                    )}
                  </div>
                </div>


  
                  <div
                    className="d-flex justify-content-center main-img-container"
                    style={{
                      ...backgroundStyle,
                      width: "100%",
                      height: "auto",
                      border: "1px solid #ccc",
                    }}
                  >
                    <img src={dynamicImgUrl} alt="Dynamic" className="bar-main" />
                  </div>
                </div>
                <div className="row justify-content-center mt-3 mb-3">
                  
                  {/* Row for the smaller images */}
                  <div className="col">
                    {dynamicImgUrl && (
                      <div className="row justify-content-center align-items-center">
                        <img
                          src={dynamicImgUrl}
                          alt="Dynamic Light"
                          className="bg-light p-1"
                          style={{
                            maxWidth: "40%",
                            height: "auto",
                            cursor: "pointer",
                          }} // Adjust size as needed
                          onClick={handleWhiteBackground}
                        />
                      </div>
                    )}
  
                    {dynamicImgUrl && (
                      <div className="row justify-content-center align-items-center mb-2">
                        <img
                          src={dynamicImgUrl}
                          alt="Dynamic Dark"
                          className="p-1"
                          style={{
                            maxWidth: "40%",
                            height: "auto",
                            cursor: "pointer",
                          }} // Adjust size as needed
                          onClick={handleBlackBackground}
                        />
                      </div>
                    )}
  
                    {dynamicImgUrl && (
                      <div className="row justify-content-center align-items-center">
                        <img
                          src={dynamicImgUrl}
                          alt="Dynamic Shadow Color"
                          className="p-1"
                          style={{
                            maxWidth: "40%",
                            height: "auto",
                            backgroundColor: shadowColor,
                            cursor: "pointer",
                          }}
                          onClick={handleShadowBackground}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
  
  
              {/* This is the left panel of the toolbar editor: */}
              <div className="col-12 col-lg-6 box-shadow gradient-bar-container ml-lg-2 mb-2">
                {/* Size Box */}
                <div className="col-12 p-0">
                  <p className="toolbar-header gradient-toolbar mt-2 py-2 pl-3">
                    Size
                  </p>
                  <div className="col-12 d-flex justify-content-between align-items-center">
                    <label htmlFor="sizeInput" className="toolbar-text">
                      Full Width
                    </label>
                    <input
                      type="text"
                      id="sizeInput"
                      name="size"
                      className="diameter-input-field"
                      value={size}
                      onChange={handleInputChange}
                      placeholder="pixels"
                    />
                  </div>
                </div>
  
                {/* Static Colors Box */}
                <div className="col-12 p-0">
                  <h5 className="toolbar-header gradient-toolbar mt-2 py-2 pl-3">
                    Static Colors
                  </h5>
                  <div className="col-12 d-flex justify-content-between align-items-center divider-line toolbar-hover toolbar-hover py-2">
                    <label htmlFor="sizeInput" className="toolbar-text">
                      Background
                    </label>
                    <input
                      type="color"
                      id="shadowColor"
                      name="shadowColor"
                      value={shadowColor}
                      onChange={(e) => handleColorChange(setShadowColor, e)}
                      className="square-color-picker"
                    />
                  </div>
  
                  <div className="col-12 d-flex justify-content-between align-items-center toolbar-hover py-2">
                    <label htmlFor="sizeInput" className="toolbar-text">
                      Border
                    </label>
                    <input
                      type="color"
                      id="outerBarColor"
                      name="outerBarColor"
                      value={outerBarColor}
                      onChange={(e) => handleColorChange(setOuterBarColor, e)}
                      className="square-color-picker"
                    />
                  </div>
                </div>
  
                {/* -------------------------------------Dynamic Bar------------------------------------- */}
  
                <div className="col-12 p-0">
                  <h5 className="toolbar-header gradient-toolbar mt-2 py-2 pl-3">
                    Dynamic Bar
                  </h5>
                  <div className="col-12 d-flex justify-content-between align-items-center divider-line toolbar-hover pb-2">
                    <label className="toolbar-text">Active Color</label>
                    <input
                      type="color"
                      id="innerBarColor"
                      name="innerBarColor"
                      value={innerBarColor}
                      onChange={(e) => handleColorChange(setInnerBarColor, e)}
                      className="square-color-picker"
                    />
                  </div>
  
                  {/* Font Color */}
                  <div className="col-12 d-flex justify-content-between align-items-center divider-line toolbar-hover py-2">
                    <label className="toolbar-text">Inactive Color</label>
                    <input
                      type="color"
                      id="innerBarOffColor"
                      name="innerBarOffColor"
                      value={innerBarOffColor}
                      onChange={(e) => handleColorChange(setInnerBarOffColor, e)}
                      className="square-color-picker"
                    />
                  </div>
  
                  {/* Bar Thickness */}
                  <div className="col-12 d-flex justify-content-between align-items-center toolbar-hover py-2">
                    <label className="toolbar-text">Weight</label>
                    <button
                      className={`bar-thickness-btn ${
                        activeSelectedButton === "largeButton" ? "active" : ""
                      }`}
                      id="largeButton"
                      onClick={() => handleThicknessButtonClick("largeButton")}
                    >
                      <img src={BarThicknessLarge} alt="Large" />
                    </button>
                    <button
                      className={`bar-thickness-btn m-0 ${
                        activeSelectedButton === "mediumButton" ? "active" : ""
                      }`}
                      id="mediumButton"
                      onClick={() => handleThicknessButtonClick("mediumButton")}
                    >
                      <img src={BarThicknessMedium} alt="Medium" />
                    </button>
                    <button
                      className={`bar-thickness-btn ${
                        activeSelectedButton === "smallButton" ? "active" : ""
                      }`}
                      id="smallButton"
                      onClick={() => handleThicknessButtonClick("smallButton")}
                    >
                      <img src={BarThicknessSmall} alt="Small" />
                    </button>
                  </div>
                </div>
              </div>
  
              {/* This is the right panel of the toolbar editor: */}
              <div className="col-12 col-lg-6 box-shadow gradient-bar-container ml-lg-2">
                <div className="col-12 p-0">
                  {/* Dynamic Color */}
                  <p className="toolbar-header gradient-toolbar mt-2 py-2 pl-3">
                    Dynamic Color
                  </p>
                  <div className="">
                    <form className="d-flex justify-content-between align-items-center divider-line toolbar-hover pb-2 mx-2">
                      {/* Color Zone Label */}
                      <div className="toolbar-text">Color Ranges</div>
  
                      
                      {/* <CFormSwitch label="" id="formSwitchCheckDefaultNormal" /> */}
  
                      <img
                        src={AddBtn}
                        alt="Add Zone"
                        className="box-shadow p-1 clickable"
                        onClick={addZone}
                      />
                    </form>
                  </div>
  
                  {zones.map((zone, index) => (
                    <div
                      key={index}
                      className="row d-flex justify-content-between align-items-center divider-line toolbar-hover mt-2 mr-1 pb-2"
                    >
                      <div className="col-4 d-flex justify-content-start align-items-center">
                        <img
                          src={MinusBtn}
                          alt="Delete Zone"
                          className="box-shadow p-1 mr-2 clickable"
                          onClick={() => deleteZone(index)}
                        />
                        <label
                          htmlFor={`zone${index + 1}Toggle`}
                          className="toolbar-text m-0"
                        >
                          {`Range ${index + 1}`}
                        </label>
                      </div>
                      <div
                        className="col-6 d-flex align-items-center justify-content-center"
                        style={{ justifyContent: "space-around" }}
                      >
                        <p className="doc-body-large" style={{ margin: 0 }}>
                          {"<"}
                        </p>
                        <input
                          type="text"
                          className="underscore-input mx-3 mt-0"
                          style={{ textAlign: "center" }}
                          placeholder=""
                          value={zone.v}
                          onChange={(e) => handleZoneChange(index, "v", e.target.value)}
                        />
                        <CTooltip
                          content={`Values less than ${zone.v} will display as the color: ${zone.c}.`}
                          placement="top"
                          className="doc-body-small"
                        >
                          <img
                            src={QuestionIcon}
                            alt="This is where an explanation will go"
                            className="box-shadow question-icon"
                            style={{ display: "block" }}
                          />
                        </CTooltip>
                      </div>
  
                      <div className="col-2 d-flex justify-content-end align-items-center">
                        <input
                          type="color"
                          value={zone.c}
                          onChange={(e) => handleZoneChange(index, "c", e.target.value)}
                          className="square-color-picker"
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
        {/* <div className="col-12 d-flex justify-content-center">
          <button onClick={newRenderComponentClick} className="render-btn">
            Render Component
          </button>
          <button onClick={resetState} className="reset-btn">
            Reset
          </button>
        </div> */}
        </div>
      </div>
      </motion.div>
    );
  };
  
  export default Bar;
  