import React, { useState, useEffect } from 'react';
import { CContainer, CRow, CCol } from '@coreui/react';
import { phpSessGet, phpSessIsLoggedIn, phpSessFullNameGet } from './PhpSession.js';
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";

const placeholderImage = 'https://via.placeholder.com/200';

function Profile() {
  const [userName, setUserName] = useState('');

  useEffect(() => {
    const fetchUserData = async () => {
      const loggedIn = await phpSessIsLoggedIn();
      if (loggedIn) {
        const fullName = phpSessFullNameGet();
        setUserName(fullName);
      }
    };

    fetchUserData();
  }, []);

  return (
    <CContainer className="container-fluid profile-container d-flex justify-content-center vh-60 mt-5 pt-5 mb-5 pb-5">
      <CRow className="w-100 d-flex mb-5">
        {/* <CCol md={5} className="d-flex flex-column justify-content-center"> */}
        <CCol md={12} className="d-flex flex-column justify-content-start mb-5 pb-5">  
          <img src={placeholderImage} alt="User Avatar" className="profile-avatar" />
          <p className="doc-header mt-2">{userName}</p>
          <p className="doc-sub-header pt-0 mt-0 mb-2">Member Status: Test User</p>
        </CCol>
        {/* <CCol md={7} className="d-flex flex-column justify-content-start"> */}
          {/* <p className="doc-header mt-2">{userName}</p> */}
          {/* <p className="doc-sub-header pt-0 mt-0 mb-2">Member Since: 00/00/00.</p> */}
          {/* <p className="doc-sub-header pt-0 mt-0 mb-2">Member Status: Test User</p> */}
          {/* <p className="doc-sub-header pt-0 mt-0 mb-2">Current Assets: #</p>
          <p className="doc-sub-header pt-0 mt-0 mb-2">Assets Created: #</p> */}
        {/* </CCol> */}
      </CRow>
    </CContainer>
  );
}

export default Profile;
