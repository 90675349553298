import React from "react";
import { Link } from "react-router-dom";
import "../App.css"; // Ensure the CSS path is correct as per your directory structure

import Introduction from "./Documentation/GettingStarted/Introduction";

const Documentation = () => {
  return (
    <div className="row justify-content-center">
      <Introduction />
    </div>
  );
};

export default Documentation;
