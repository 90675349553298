import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
// Import your App.css if it contains additional styles
import "../App.css";

import Youtube from "./images/Youtube.svg";
import Linkedin from "./images/Linkedin.svg";
import Twitter from "./images/Twitter.svg";
import Instagram from "./images/Instagram.svg";
import Facebook from "./images/Facebook.svg";



const Footer = () => {
  return (

<div className="container-fluid footer-bg mt-5 box-shadow">

  <footer className="text-center text-white">

    <div className="container-fluid social-media-icons d-flex justify-content-center align-items-center mr-5 pt-3 mt-3 mb-2 footer-bg">
      <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer">
        <img src={Youtube} alt="YouTube" className="social-icon" />
      </a>
      <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
        <img src={Linkedin} alt="LinkedIn" className="social-icon" />
      </a>
      <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
        <img src={Twitter} alt="Twitter" className="social-icon" />
      </a>
      <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
        <img src={Instagram} alt="Instagram" className="social-icon" />
      </a>
      <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
        <img src={Facebook} alt="Facebook" className="social-icon" />
      </a>
    </div>

  <div className="text-center mt- 2 p-3">
    © 2024 Copyright:
    <a className="text-white px-2" href="https://mydesign99.com/">MyDesign99</a>
  </div>

</footer>
  
</div>

  );
};

export default Footer;
