import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { apiLogout } from './ApiClient';
import { phpSessIsLoggedIn, phpSessLogout, phpSessGet, phpSessFullNameGet } from './PhpSession.js';
import toast from "react-hot-toast";

import MD99Logo from "./images/type-1000-xl.png";
import { 
  CNavbar, 
  CContainer, 
  CNavbarBrand, 
  CNavbarToggler, 
  CCollapse, 
  CNavbarNav, 
  CNavItem, 
  CNavLink, 
  CBadge,
  CAvatar,
  CTooltip
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { cilUser } from '@coreui/icons';

const placeholderImage = 'https://ui-avatars.com/api/?name=User&background=random&rounded=true';

function TopNav({ assetsCount, resetAssetsCount }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [visible, setVisible] = useState(false);
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [userName, setUserName] = useState('');

  useEffect(() => {
    const checkLoginStatus = async () => {
      const loggedIn = await phpSessIsLoggedIn();
      setUserLoggedIn(loggedIn);
      if (loggedIn) {
        const sessID = phpSessGet();
        setUserName(sessID);
      }
    };

    checkLoginStatus();
  }, []);

  useEffect(() => {
    const checkLoginStatus = async () => {
      const loggedIn = await phpSessIsLoggedIn();
      setUserLoggedIn(loggedIn);
      if (loggedIn) {
        // const sessID = phpSessGet();
        setUserName(phpSessFullNameGet());
      }
    };

    checkLoginStatus();
  }, [location]);

  const handleNavCollapse = () => setVisible(!visible);

  const handleLogin = async () => {
    setUserLoggedIn(true);
    navigate("/"); // Navigate to home page
  };

  const handleSignup = () => {
    navigate("/", { state: { showSignup: true } }); // Navigate to home page with state to show Signup
  };

  const handleLogout = async () => {
    await apiLogout(); // Ensure the server logout is called
    phpSessLogout();   // Clear the session locally
    setUserLoggedIn(false); // Update the login status
    resetAssetsCount(); // Reset the assets count
    setUserName(''); // Clear the user name
    navigate("/");
    toast.success('You have successfully logged out', {
      duration: 3000,
    });
  };

  const closeMenu = () => setVisible(false);

  return (
    <CNavbar expand="lg" className="top-nav pt-4">
      <CContainer fluid>
        <CNavbarBrand>
          <Link to="/" onClick={closeMenu}>
            <img src={MD99Logo} alt="Color Logo Icon" className="logo-nav pl-3" />
          </Link>
        </CNavbarBrand>
        <CNavbarToggler
          aria-label="Toggle navigation"
          aria-expanded={visible}
          onClick={handleNavCollapse}
        />
        <CCollapse className="navbar-collapse" visible={visible}>
          <CNavbarNav className="mx-auto">
            <CNavItem>
              <Link className="nav-link top-nav-text py-2 px-5" to="/" onClick={closeMenu}>
                Home
              </Link>
            </CNavItem>
            <CNavItem className="position-relative">
              <Link className="nav-link top-nav-text py-2 px-5" to="/dashboard" onClick={closeMenu}>
                My Assets
                <CBadge shape="rounded-pill" className="badge-custom position-absolute top-0 start-35">
                  {assetsCount}
                </CBadge>
              </Link>
            </CNavItem>
            
            <CNavItem>
              <Link className="nav-link top-nav-text py-2 px-5" to="/documentation/dynamic_routing" onClick={closeMenu}>
                Documentation
              </Link>
            </CNavItem>

            <CNavItem>
              <Link className="nav-link top-nav-text py-2 px-5" to="/pricing/info" onClick={closeMenu}>
                Pricing
              </Link>
            </CNavItem>
          </CNavbarNav>
          {userLoggedIn ? (
            <div className="d-flex align-items-center">
              <span className="top-nav-text mr-2 p-0">{userName}</span>
              <CTooltip content="Profile" placement="bottom">
                <CAvatar
                  src={placeholderImage} // Use placeholder image
                  size="md"
                  status="success"
                  onClick={() => navigate("/profile")}
                  className="mr-3 avatar-img"
                  style={{ cursor: 'pointer' }}
                />
              </CTooltip>
              <button className="btn login-btn-top ml-auto pr-5" onClick={() => { handleLogout(); closeMenu(); }}>
    Logout
</button>

            </div>
          ) : (
            <div className="d-flex">
              <button className="btn signup-btn-top ml-auto" onClick={() => { handleSignup(); closeMenu(); }}>
                Signup
              </button>
              <button className="btn login-btn-top py-2 pr-5" onClick={() => { handleLogin(); closeMenu(); }}>
                Login
              </button>
            </div>
          )}
        </CCollapse>
      </CContainer>
    </CNavbar>
  );
}

export default TopNav;
