import React, { useState } from "react";
import axios from "axios";
import toast from 'react-hot-toast';
import { apiLogin } from './ApiClient';
import { motion } from "framer-motion";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  CForm,
  CFormInput,
  CButton,
  CFormFeedback
} from '@coreui/react';
import { phpSessNameSet } from "./PhpSession";

function Login({ navigate, handleLoginSuccess }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailValid, setEmailValid] = useState(null);
  const [validated, setValidated] = useState(false);

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleEmailInputChange = (event) => {
    const value = event.target.value;
    setEmail(value);
    setEmailValid(validateEmail(value));
  };

  const handlePasswordInputChange = (event) => {
    const value = event.target.value;
    setPassword(value);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleButtonLoginClick(event);
    }
  };

  const handleButtonLoginClick = (event) => {
    event.preventDefault();
    setValidated(true);

    if (!emailValid) {
      return;
    }

    const post_data = { username: email, password: password };
    apiLogin(post_data, function(data) {
      if (data && data.lsi_session_id) {
        toast.success('Login successful!');
        handleLoginSuccess(); // Trigger the navigation callback
        phpSessNameSet(data.first_name, data.last_name);
      } else {
        toast.error('Failed to login. Please check your credentials.');
      }
    });
  };

  return (
    <motion.div
      className="box"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        duration: 0.5,
        ease: "easeOut"
      }}
    >
      <div className="login-container">
        <div className="welcome-wrapper">
          <h1 className="d-flex justify-content-center subheader-text pt-1 py-1">
            Welcome!
          </h1>
        </div>

        <CForm 
          className="row g-3 needs-validation frame-parent mb-2" 
          noValidate 
          validated={validated} 
          onSubmit={handleButtonLoginClick}
        >
          <div className="frame-group">
            <div className="input-wrapper">
              <label htmlFor="emailInput" className="form-label input-label-text">
                Email
              </label>
              <CFormInput
                type="email"
                id="emailBinding"
                name="email"
                className={`input-field-login p-3 ${emailValid === true ? 'is-valid' : emailValid === false ? 'is-invalid' : ''}`}
                value={email}
                onChange={handleEmailInputChange}
                onKeyDown={handleKeyPress}
                required
              />
              <CFormFeedback valid>Looks good!</CFormFeedback>
              <CFormFeedback invalid>Please provide a valid email.</CFormFeedback>
            </div>

            <div className="input-wrapper">
              <label htmlFor="passwordInput" className="form-label input-label-text">
                Password
              </label>
              <CFormInput
                type="password"
                id="passwordInput"
                name="password"
                className="input-field-login p-3"
                value={password}
                onChange={handlePasswordInputChange}
                onKeyDown={handleKeyPress}
                required
              />
            </div>
          </div>

          <div className="group-frame d-flex justify-content-center flex-row">
            <div className="rectangle-parent">
              <div className="rectangle-div" />
              <button className="sign-up signup-btn pt-2 px-5 py-2 mb-2" onClick={() => navigate("SignUp")}>Sign up</button>
            </div>
            <div className="rectangle-group">
              <div className="rectangle-div" />
              <CButton className="login1 create-btn pt-2 px-5 py-2 mb-2" type="submit">Login</CButton>
            </div>
          </div>

          <div className="d-flex justify-content-center mt-4">
            <button
              className="forgot-password-link"
              onClick={() => navigate("ConfirmAccount")}
            >
              Forgot Password?
            </button>
          </div>
        </CForm>
      </div>
    </motion.div>
  );
}

export default Login;
