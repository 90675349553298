import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState, useRef } from "react";
import { CFormSwitch, CTooltip } from "@coreui/react";
import { useParams, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import axios from "axios";
import { apiGetOneAsset, apiSaveOneAsset } from "../Components/ApiClient";
import toast from "react-hot-toast";
import { phpSessGet, phpSessSet, phpSessLogout, phpSessIsLoggedIn } from '../Components/PhpSession.js'
import BackBtn from "../Components/images/back-btn.svg";
import { useNavigate } from "react-router-dom";

import RingThicknessLarge from "../Components/images/ring-thickness-large.svg";
import RingThicknessMedium from "../Components/images/ring-thickness-medium.svg";
import RingThicknessSmall from "../Components/images/ring-thickness-small.svg";
import TpBg from "../Components/images/tp-bg.svg";
import RadialTestSVG from "../Components/RadialTest.svg";
import AddBtn from "../Components/images/add-btn.svg";
import MinusBtn from "../Components/images/minus-btn.svg";
import QuestionIcon from "../Components/images/question-icon.svg";
import HistoryBack from "../Components/images/history-back-icon.svg";
import HistoryForward from "../Components/images/history-forward-icon.svg";
import SaveAsset from "../Components/images/save-asset-icon.svg";
import StageAsset from "../Components/images/stage-asset-icon.svg";
import Edit from "../Components/images/edit.png";

const RadialBasic = () => {
  const all_params = useParams();
  const isMounted = useRef(false);
  const [assetInputName, setAssetInputName] = useState("");
  const location = useLocation();
  const { assetName, sessID } = location.state || {};
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [size, setSize] = useState("");
  const [outerRingActiveBtn, setOuterRingActiveBtn] = useState("");
  const [innerRingActiveBtn, setInnerRingActiveBtn] = useState("");
  const [centerFillActiveBtn, setCenterFillActiveBtn] = useState("");
  const [outerRingColor, setOuterRingColor] = useState("#3d3d42");
  const [innerRingColor, setInnerRingColor] = useState("#3d3d42");
  const [innerRingOffColor, setInnerRingOffColor] = useState("#3d3d42");
  const [shadowColor, setShadowColor] = useState("#29292D");
  const [centerFillColor, setCenterFillColor] = useState("#3D3D42");
  const [fontFamily, setFontFamily] = useState("bebasneue-regular");
  const [fontColor, setFontColor] = useState("#29292D");
  const [radioValue, setRadioValue] = useState("off");
  const [selectedRingThickness, setSelectedRingThickness] = useState("l");
  const [activeButton, setActiveButton] = useState(null);
  const [dynamicImgUrl, setDynamicImgUrl] = useState("");

  const [dynamicColorActive, setDynamicColorActive] = useState(false);
  const [colorZone1Active, setColorZone1Active] = useState(false);
  const [colorZone2Active, setColorZone2Active] = useState(false);
  const [colorZone3Active, setColorZone3Active] = useState(false);
  const [colorZone4Active, setColorZone4Active] = useState(false);
  const [colorZone1Selection, setColorZone1Selection] = useState("#2fdf0c");
  const [colorZone2Selection, setColorZone2Selection] = useState("#f0f410");
  const [colorZone3Selection, setColorZone3Selection] = useState("#f07400");
  const [colorZone4Selection, setColorZone4Selection] = useState("#f00000");
  const [zone1MaxInput, setzone1MaxInput] = useState("");
  const [zone2MaxInput, setzone2MaxInput] = useState("");
  const [zone3MaxInput, setzone3MaxInput] = useState("");
  const [zone4MaxInput, setzoneMaxInput] = useState("");
  const [dynamicImgSrc, setDynamicImgSrc] = useState(RadialTestSVG);
  const [radialData, setRadialData] = useState({});
  const [backgroundStyle, setBackgroundStyle] = useState({
    backgroundColor: "white",
    backgroundImage: "",
  });
  const [zones, setZones] = useState([
    { name: "Zone 1", v: "", c: "#2fdf0c", in: "0" },
  ]);

  const [isEditing, setIsEditing] = useState(false);

  const addZone = () => {
    if (zones.length < 5) {
      const newZoneNumber = zones.length + 1;
      setZones([...zones, { name: `Zone ${newZoneNumber}`, v: "", c: "#2fdf0c", in: "0" }]);
    }
  };
  
  const deleteZone = (index) => {
    const updatedZones = zones.filter((_, i) => i !== index);
    setZones(updatedZones);
  };
  
  const handleZoneChange = (index, field, value) => {
    const updatedZones = zones.map((zone, i) =>
      i === index ? { ...zone, [field]: value } : zone
    );
    setZones(updatedZones);
  };

  const retrieveUserLastDesign = () => {
    apiGetOneAsset(all_params.asset_id, function (data) {
      setDynamicImgUrl(data.image);
      const params = JSON.parse(data.params);
  
      setSize(params.full_w);
      setSelectedRingThickness(params.wedge_w);
      setShadowColor(params.shadow_c);
      setOuterRingColor(params.outer_c);
      setInnerRingColor(params.on_c);
      setInnerRingOffColor(params.off_c);
      setCenterFillColor(params.inner_c);
      setFontColor(params.font_c);
      setFontFamily(params.font_fam);
  
      var ranges = [];
      if (params.hasOwnProperty('ranges')) {
        ranges = params.ranges;
      }
      
      const parsedZones = ranges.map((range, index) => ({
        name: `Zone ${index + 1}`,
        v: range.v.toString(),
        c: range.c,
        in: "0",
      }));

      setZones(parsedZones);
    });
  };

  useEffect(() => {
    if (sessID) {
      phpSessSet(sessID); // Ensure the session ID is set
    }
    setAssetInputName(assetName);
    retrieveUserLastDesign();
  }, [sessID, assetName]);

  const activeSelectedButton = selectedRingThickness
    ? selectedRingThickness === "l"
      ? "largeButton"
      : selectedRingThickness === "m"
      ? "mediumButton"
      : "smallButton"
    : "";

  const handleMainRadioChange = (event) => {
    const value = event.target.value;
    setDynamicColorActive(value === "on");
  };

  const handleZone1Toggle = () => {
    setColorZone1Active((prevState) => !prevState);
  };

  const handleZone2Toggle = () => {
    setColorZone2Active((prevState) => !prevState);
  };

  const handleZone3Toggle = () => {
    setColorZone3Active((prevState) => !prevState);
  };

  const handleZone4Toggle = () => {
    setColorZone4Active((prevState) => !prevState);
  };

  const handleColorChange = (colorSetter, event) => {
    colorSetter(event.target.value);
  };

  const handleInputChange = (event) => {
    setSize(event.target.value);
  };

  const handleAssetInputNameChange = (event) => {
    setAssetInputName(event.target.value);
  };

  const handleFontFamilyChange = (event) => {
    setFontFamily(event.target.value);
  };

  const handleFontColorChange = (event) => {
    setFontColor(event.target.value);
  };

  const handleUsernameInputChange = (e) => {
    setUsername(e.target.value);
  };

  const handleThicknessButtonClick = (buttonId) => {
    setActiveButton(buttonId);
    let thicknessLetter = "";
    if (buttonId === "largeButton") {
      thicknessLetter = "l";
    } else if (buttonId === "mediumButton") {
      thicknessLetter = "m";
    } else if (buttonId === "smallButton") {
      thicknessLetter = "s";
    }
    setSelectedRingThickness(thicknessLetter);
  };

  const handleZone1MaxChange = (event) => {
    setzone1MaxInput(parseInt(event.target.value, 10) || 0);
  };

  const handleZone2MaxChange = (event) => {
    setzone2MaxInput(parseInt(event.target.value, 10) || 0);
  };

  const handleZone3MaxChange = (event) => {
    setzone3MaxInput(parseInt(event.target.value, 10) || 0);
  };

  const handleZone4MaxChange = (event) => {
    setzoneMaxInput(parseInt(event.target.value, 10) || 0);
  };

  const handleWhiteBackground = () => {
    setBackgroundStyle({
      backgroundColor: "white",
      backgroundImage: "",
    });
  };

  const handleBlackBackground = () => {
    setBackgroundStyle({
      backgroundColor: "black",
      backgroundImage: "",
    });
  };

  const handleTransparentBackground = () => {
    setBackgroundStyle({
      backgroundColor: "transparent",
      backgroundImage: `url(${TpBg})`,
      backgroundSize: "cover",
    });
  };

  const handleShadowColorBackground = () => {
    setBackgroundStyle({
      backgroundColor: shadowColor,
      backgroundImage: "",
    });
  };

  useEffect(() => {
    if (isMounted.current) {
      if (
        outerRingColor !== "#3d3d42" ||
        innerRingColor !== "#3d3d42" ||
        innerRingOffColor !== "#3d3d42" ||
        shadowColor !== "#3d3d42" ||
        centerFillColor !== "#3d3d42" ||
        fontColor !== "#3d3d42" ||
        fontFamily !== "xl" ||
        selectedRingThickness !== "xl" ||
        zones.some(zone => zone.color !== "#2fdf0c" || zone.value !== "")
      ) {
        newRenderComponentClick();
      }
    } else {
      isMounted.current = true;
    }
  }, [
    outerRingColor,
    innerRingColor,
    innerRingOffColor,
    shadowColor,
    centerFillColor,
    fontColor,
    fontFamily,
    selectedRingThickness,
    zones,
  ]);

  const renderComponentClick = () => {};

  const newRenderComponentClick = () => {
    const post_data = {
      asset_id: all_params.asset_id,
      full_w: 400,
      outer_c: outerRingColor,
      on_c: innerRingColor,
      off_c: innerRingOffColor,
      shadow_c: shadowColor,
      inner_c: centerFillColor,
      font_fam: fontFamily,
      font_c: fontColor,
      wedge_w: selectedRingThickness,
      ranges: zones,
    };

    apiSaveOneAsset(post_data, function (data) {
      setDynamicImgUrl(data.image);
    });
  };

  const handleButtonClick = (section, button) => {
    switch (section) {
      case "outer":
        setOuterRingActiveBtn(button);
        break;
      case "inner":
        setInnerRingActiveBtn(button);
        break;
      case "center":
        setCenterFillActiveBtn(button);
        break;
      default:
        break;
    }
  };

  const getButtonClasses = (section, button) => {
    let isActive = false;
    switch (section) {
      case "outer":
        isActive = outerRingActiveBtn === button;
        break;
      case "inner":
        isActive = innerRingActiveBtn === button;
        break;
      case "center":
        isActive = centerFillActiveBtn === button;
        break;
      default:
        break;
    }
    return `mx-1 size-selector px-3 ${isActive ? "active-button" : ""}`;
  };

  useEffect(() => {
    handleShadowColorBackground();
  }, [shadowColor]);

  const resetState = () => {
    setSize("");
    setOuterRingActiveBtn("");
    setInnerRingActiveBtn("");
    setCenterFillActiveBtn("");
    setOuterRingColor("#3d3d42");
    setInnerRingColor("#3d3d42");
    setInnerRingOffColor("#3D3D42");
    setCenterFillColor("#3D3D42");
    setFontColor("#29292D");
    setShadowColor("#29292D");
    setColorZone1Selection("#2fdf0c");
    setColorZone2Selection("#f0f410");
    setColorZone3Selection("#f07400");
    setColorZone4Selection("#f00000");
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    setAssetInputName(assetName);
  };

  const handleConfirmClick = () => {
    // Placeholder for future server-side logic
    toast.success('Asset Name Updated!');
    setIsEditing(false);
  };

  return (
    <motion.div
      className="box"
      initial={{ opacity: 0, scale: 0.95 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{
        duration: 1.5,
        ease: "easeOut",
        scale: {
          type: "spring",
          damping: 10,
          stiffness: 50,
          restDelta: 0.001
        }
      }}
    >
      <div className="container-fluid bg-main mx-2 pt-5">
        <div className="col-12">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="d-flex flex-wrap justify-content-center">
                <div className="col-lg-4 col-md-6 col-sm-12 gradient-img-container m-1 align-items-center">
                  <div className="col-12">
                  <div className="col-12 p-0 position-relative d-flex justify-content-center">
                    <div className="d-flex align-items-center justify-content-center my-3" style={{ width: '100%' }}>
                      <img
                        className="clickable back-arrow-btn"
                        loading="lazy"
                        alt="back-btn"
                        src={BackBtn}
                        onClick={() => navigate("/dashboard")}
                        style={{ marginRight: '8px' }}
                      />
                      <input
                        type="text"
                        id="assetNameInput"
                        name="assetName"
                        className={`form-control flex-grow-1 asset-name-field ${!isEditing ? 'no-pointer-events' : ''}`}
                        value={assetInputName}
                        onChange={handleAssetInputNameChange}
                        placeholder="my-asset-name"
                      />
                      {isEditing ? (
                        <>
                          <button onClick={handleCancelClick} className="btn text-danger name-change ml-2">✕</button>
                          <button onClick={handleConfirmClick} className="btn text-success name-change ml-2">✓</button>
                        </>
                      ) : (
                        <img
                          className="clickable edit-asset-name-btn ml-2"
                          loading="lazy"
                          alt="Edit Asset Name"
                          src={Edit}
                          onClick={handleEditClick}
                        />
                      )}
                    </div>
                  </div>

                    <div
                      className="d-flex justify-content-center main-img-container"
                      style={{
                        ...backgroundStyle,
                        width: "100%",
                        height: "auto",
                        border: "1px solid #ccc",
                      }}
                    >
                      <img
                        src={dynamicImgUrl}
                        alt="Dynamic"
                        className="radial-main"
                      />
                    </div>
                  </div>
                  <div className="row justify-content-center mt-3 mb-3">
                    <div className="col d-flex justify-content-center align-items-center">
                      <img
                        src={dynamicImgUrl}
                        alt="White Background"
                        className="canvas-toggle-light bg-light p-1"
                        style={{
                          maxWidth: "30%",
                          height: "auto",
                          cursor: "pointer",
                        }}
                        onClick={handleWhiteBackground}
                      />
                      <img
                        src={dynamicImgUrl}
                        alt="Black Background"
                        className="canvas-toggle-dark p-1"
                        style={{
                          maxWidth: "30%",
                          height: "auto",
                          cursor: "pointer",
                          backgroundColor: "black",
                        }}
                        onClick={handleBlackBackground}
                      />
                      <img
                        src={dynamicImgUrl}
                        alt="Custom Background"
                        className="canvas-toggle-dark p-1"
                        style={{
                          maxWidth: "30%",
                          height: "auto",
                          cursor: "pointer",
                          backgroundColor: shadowColor,
                        }}
                        onClick={handleShadowColorBackground}
                      />
                    </div>
                  </div>
                </div>

                <div
                  className="col-lg-4 d-flex flex-column box-shadow gradient-container m-1 radial-container"
                  style={{ overflow: "hidden", maxWidth: "100%" }}
                >
                  <div className="col-12 p-0">
                    <p className="toolbar-header gradient-toolbar mt-2 py-2 pl-3">
                      Size
                    </p>
                    <div className="col-12 d-flex justify-content-between align-items-center">
                      <label htmlFor="sizeInput" className="toolbar-text">
                        Full Diameter
                      </label>
                      <input
                        type="text"
                        id="sizeInput"
                        name="size"
                        className="diameter-input-field"
                        value={size}
                        onChange={handleInputChange}
                        placeholder="pixels"
                      />
                    </div>
                  </div>
                  <div className="col-12 p-0">
                    <h5 className="toolbar-header gradient-toolbar mt-2 py-2 pl-3">
                      Static Colors
                    </h5>
                    <div className="col-12 d-flex justify-content-between align-items-center divider-line toolbar-hover toolbar-hover py-2">
                      <label htmlFor="sizeInput" className="toolbar-text">
                        Background
                      </label>
                      <input
                        type="color"
                        id="shadowColor"
                        name="shadowColor"
                        value={shadowColor}
                        onChange={(e) => handleColorChange(setShadowColor, e)}
                        className="square-color-picker"
                      />
                    </div>
                    <div className="col-12 d-flex justify-content-between align-items-center divider-line toolbar-hover py-2">
                      <label htmlFor="sizeInput" className="toolbar-text">
                        Border Ring
                      </label>
                      <input
                        type="color"
                        id="outerRingColor"
                        name="outerRingColor"
                        value={outerRingColor}
                        onChange={(e) => handleColorChange(setOuterRingColor, e)}
                        className="square-color-picker"
                      />
                    </div>
                    <div className="col-12 d-flex justify-content-between align-items-center py-2 toolbar-hover">
                      <label
                        htmlFor="sizeInput"
                        className="toolbar-text d-flex align-items-center"
                      >
                        Center Fill
                      </label>
                      <input
                        type="color"
                        id="centerFillColor"
                        name="centerFillColor"
                        value={centerFillColor}
                        onChange={(e) =>
                          handleColorChange(setCenterFillColor, e)
                        }
                        className="square-color-picker"
                      />
                    </div>
                  </div>
                  <div className="col-12 p-0">
                    <h5 className="toolbar-header gradient-toolbar py-2 pl-3">
                      Font
                    </h5>
                    <div className="col-12 d-flex justify-content-between align-items-center divider-line toolbar-hover py-2">
                      <label htmlFor="sizeInput" className="toolbar-text">
                        Font Family
                      </label>
                      <select
                        id="fontFamily"
                        name="fontFamily"
                        className="input-field-font"
                        value={fontFamily}
                        onChange={handleFontFamilyChange}
                      >
                        <option
                          className="bebasneue-regular"
                          value="bebasneue-regular"
                        >
                          Bebasneue-Regular
                        </option>
                        <option
                          className="bungeehairline-regular"
                          value="bungeehairline-regular"
                        >
                          Bungee Hair Line-Regular
                        </option>
                        <option
                          className="jetbrains-mono-regular"
                          value="jetbrains-mono-regular"
                        >
                          Jetbrains-Mono-Regular
                        </option>
                        <option
                          className="jetbrains-mono-bold"
                          value="jetbrains-mono-bold"
                        >
                          Jetbrains-Mono-Bold
                        </option>
                        <option className="lato-regular" value="lato-regular">
                          Lato-Regular
                        </option>
                        <option className="lato-bold" value="lato-bold">
                          Lato-Bold
                        </option>
                        <option
                          className="leaguespartan-regular"
                          value="leaguespartan-regular"
                        >
                          League Spartan-Regular
                        </option>
                        <option
                          className="leaguespartan-bold"
                          value="leaguespartan-bold"
                        >
                          League Spartan-Bold
                        </option>
                        <option className="lexend-regular" value="lexend-regular">
                          Lexend-Regular
                        </option>
                        <option className="lexend-bold" value="lexend-bold">
                          Lexend-Bold
                        </option>
                        <option
                          className="montserrat-regular"
                          value="montserrat-regular"
                        >
                          Montserrat-Regular
                        </option>
                        <option
                          className="montserrat-bold"
                          value="montserrat-bold"
                        >
                          Montserrat-Bold
                        </option>
                        <option
                          className="robocondensed-regular"
                          value="robotocondensed-regular"
                        >
                          Roboto-Condensed-Regular
                        </option>
                        <option
                          className="robocondensed-bold"
                          value="robotocondensed-bold"
                        >
                          Roboro-Condensed-Bold
                        </option>
                      </select>
                    </div>
                    <div className="col-12 d-flex justify-content-between align-items-center toolbar-hover py-2">
                      <label htmlFor="fontColor" className="toolbar-text">
                        Font Color
                      </label>
                      <input
                        type="color"
                        id="fontColor"
                        name="fontColor"
                        value={fontColor}
                        onChange={handleFontColorChange}
                        className="square-color-picker"
                      />
                    </div>
                  </div>
                  <div className="col-12 p-0">
                    <h5 className="toolbar-header gradient-toolbar mt-2 py-2 pl-3">
                      Dynamic Ring
                    </h5>
                    <div className="col-12 d-flex justify-content-between align-items-center divider-line toolbar-hover pb-2">
                      <label className="toolbar-text">Active Color</label>
                      <input
                        type="color"
                        id="innerRingColor"
                        name="innerRingColor"
                        value={innerRingColor}
                        onChange={(e) => handleColorChange(setInnerRingColor, e)}
                        className="square-color-picker"
                      />
                    </div>
                    <div className="col-12 d-flex justify-content-between align-items-center divider-line toolbar-hover py-2">
                      <label className="toolbar-text">Inactive Color</label>
                      <input
                        type="color"
                        id="innerRingOffColor"
                        name="innerRingOffColor"
                        value={innerRingOffColor}
                        onChange={(e) =>
                          handleColorChange(setInnerRingOffColor, e)
                        }
                        className="square-color-picker"
                      />
                    </div>
                    <div className="col-12 d-flex justify-content-between align-items-center toolbar-hover py-2">
                      <label className="toolbar-text">Weight</label>
                      <button
                        className={`ring-thickness-btn ${
                          activeSelectedButton === "largeButton" ? "active" : ""
                        }`}
                        id="largeButton"
                        onClick={() => handleThicknessButtonClick("largeButton")}
                      >
                        <img src={RingThicknessLarge} alt="Large" />
                      </button>
                      <button
                        className={`ring-thickness-btn ${
                          activeSelectedButton === "mediumButton" ? "active" : ""
                        }`}
                        id="mediumButton"
                        onClick={() => handleThicknessButtonClick("mediumButton")}
                      >
                        <img src={RingThicknessMedium} alt="Medium" />
                      </button>
                      <button
                        className={`ring-thickness-btn ${
                          activeSelectedButton === "smallButton" ? "active" : ""
                        }`}
                        id="smallButton"
                        onClick={() => handleThicknessButtonClick("smallButton")}
                      >
                        <img src={RingThicknessSmall} alt="Small" />
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 d-flex box-shadow gradient-container m-1"
                  style={{ overflow: "hidden", maxWidth: "100%" }}
                >
                  <div className="col-12 p-0">
                    <h5 className="toolbar-header gradient-toolbar py-2 pl-3 m-2">
                      Dynamic Color
                    </h5>
                    <div className="">
                      <form className="d-flex justify-content-between align-items-center divider-line toolbar-hover pb-2">
                        <div className="toolbar-text">Color Ranges</div>
                        {/* <CFormSwitch label="" id="formSwitchCheckDefaultNormal" /> */}
                        <img
                          src={AddBtn}
                          alt="Add Zone"
                          className="box-shadow p-1 clickable"
                          onClick={addZone}
                        />
                      </form>
                    </div>
                    
                    {zones.map((zone, index) => (
                      <div
                        key={index}
                        className="row d-flex justify-content-between align-items-center divider-line toolbar-hover mt-2 mr-1 pb-2"
                      >
                        <div className="col-4 d-flex justify-content-start align-items-center">
                          <img
                            src={MinusBtn}
                            alt="Delete Zone"
                            className="box-shadow p-1 mr-2 clickable"
                            onClick={() => deleteZone(index)}
                          />
                          <label
                            htmlFor={`zone${index + 1}Toggle`}
                            className="toolbar-text m-0"
                          >
                            {`Range ${index + 1}`}
                          </label>
                        </div>
                        <div
                          className="col-6 d-flex align-items-center justify-content-center"
                          style={{ justifyContent: "space-around" }}
                        >
                          <p className="doc-body-large" style={{ margin: 0 }}>
                            {"<"}
                          </p>
                          <input
                            type="text"
                            className="underscore-input mx-3 mt-0"
                            style={{ textAlign: "center" }}
                            placeholder=""
                            value={zone.v}
                            onChange={(e) => handleZoneChange(index, "v", e.target.value)}
                          />
                          <CTooltip
                            content={`Values less than ${zone.v} will display as the color: ${zone.c}.`}
                            placement="top"
                            className="doc-body-small"
                          >
                            <img
                              src={QuestionIcon}
                              alt="This is where an explanation will go"
                              className="box-shadow question-icon"
                              style={{ display: "block" }}
                            />
                          </CTooltip>
                        </div>
                        <div className="col-2 d-flex justify-content-end align-items-center">
                          <input
                            type="color"
                            value={zone.c}
                            onChange={(e) => handleZoneChange(index, "c", e.target.value)}
                            className="square-color-picker"
                          />
                        </div>
                      </div>
                    ))}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default RadialBasic;
