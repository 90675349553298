import React, { useEffect, useState, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { apiGetAllAssets, apiDeleteAsset, apiGetDashboardData, apiAddAsset } from './ApiClient';
import { authTokenBuildUrl } from './AuthToken.js';
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import "../App.css";
import { useNavigate } from "react-router-dom";
import toast from 'react-hot-toast';
import { motion } from "framer-motion";
import { phpSessIsLoggedIn, phpSessGet } from './PhpSession.js';
import DividerLine from "./images/divider-line.svg";

import TrashCan from "./images/trash-can.png";
import Edit from "./images/edit.png";
import EyeBall from "./images/eye-ball.png";
import RadialThumbnail from "./images/image 7radial-thumbnail.png";
import RadialIcon from "./images/green.svg";
import CopyCode from "./images/copy-code.png";
import Refresh from "./images/refresh.png";
import AddAsset from "./images/add-asset.svg";
import EditAsset from "./images/edit-asset.svg";
import Circle from "./images/circle-btn.svg";
import Arrow from "./images/arrow-btn.svg";
import Bar from "./images/bar-btn.svg";
import { toPng } from 'html-to-image';
import DownloadPNG from "./images/download-png.svg";

const DashboardManagerConcept = ({ setAssetsCount }) => {
  const navigate = useNavigate();
  const [openDelete, setOpenDelete] = useState(false);
  const [openRefresh, setOpenRefresh] = useState(false);
  const [currentAssetName, setCurrentAssetName] = useState("");
  const [currentAssetId, setCurrentAssetId] = useState(null);
  const [password, setPassword] = useState("");
  const [publicKey, setPublicKey] = useState("");
  const [secretKey, setSecretKey] = useState("");
  const [allData, setAllData] = useState([]);
  const [assetValue, setAssetValue] = useState("0");  // Set default value to 0
  const [imgUrl, setImgUrl] = useState("https://mydesign99.com/get/YOUR-PUBLIC-KEY/YOUR-AUTHENTICATION-TOKEN/YOUR-DYNAMIC-VALUE/image-name.png");
  const [urlUpdated, setUrlUpdated] = useState(false);  // State to handle URL update animation
  const [assetName, setAssetName] = useState("");
  const [selectedComponentId, setSelectedComponentId] = useState("");
  const [activeButton, setActiveButton] = useState(null);
  const [sessionToken, setSessionToken] = useState("");
  const [openNewAssetModal, setOpenNewAssetModal] = useState(false);
  const [conceptState, setConceptState] = useState(0);
  const imageRef = useRef(null);

  useEffect(() => {
    const authenticated = phpSessIsLoggedIn();
    setSessionToken(phpSessGet());
    if (authenticated) {
      apiGetDashboardData(function (data) {
        if (data && data.assets && data.public_key && data.secret_key) {
          setAllData(data.assets);
          setAssetsCount(data.assets.length);
          setPublicKey(data.public_key);
          setSecretKey(data.secret_key);
        } else {
          toast.error('Failed to login. Please check your credentials.');
        }
      });
    } else {
      setAllData([]);
      setAssetsCount(0);
    }
  }, [setAssetsCount]);

  const handleCancelDelete = (event) => {
    if (event) {
      event.stopPropagation();
    }
    setOpenDelete(false);
  };

  const handleCancelRefresh = (event) => {
    if (event) {
      event.stopPropagation();
    }
    setOpenRefresh(false);
  };

  const handleConfirmDelete = () => {
    if (!password) {
      toast.error("Please enter your password.");
      return;
    }
    apiDeleteAsset(currentAssetId, password, (response) => {
      toast.success(`${currentAssetName} has been deleted.`);
      setOpenDelete(false);
      setPassword("");
      apiGetAllAssets((data) => {
        setAllData(data);
        setAssetsCount(data.length);
      });
    });
  };

  const handleConfirmUpdate = () => {
    if (!assetValue) {
      toast.error("Please enter a valid value.");
      return;
    }
    apiDeleteAsset(currentAssetId, assetValue, (response) => {
      toast.success(`${currentAssetName} has been deleted.`);
      setOpenRefresh(false);
      setPassword("");
      apiGetAllAssets((data) => {
        setAllData(data);
        setAssetsCount(data.length);
      });
    });
  };

  const onOpenDeleteModal = (assetId, assetName, event) => {
    event.stopPropagation();
    setCurrentAssetName(assetName);
    setCurrentAssetId(assetId);
    setOpenDelete(true);
  };

  const handleInputChange = (event) => {
    setAssetValue(event.target.value);
  };

  const onOpenRefreshModal = (assetId, assetName, event) => {
    event.stopPropagation();
    setCurrentAssetName(assetName);
    setCurrentAssetId(assetId);
    setOpenRefresh(true);
    setAssetValue("0"); // Set default value to 0 when modal is opened
    handleBuildUrl();  // Trigger the build URL when the modal opens
  };

  const openEditor = (component_name, asset_id, asset_name, event) => {
    if (openDelete || openRefresh) {
      event.stopPropagation();
      return;
    }

    var editorName = "radialbasic";
    switch (component_name) {
      case "seg_bar16":
      case "seg_bar32":
      case "seg_bar64":
        editorName = "bar";
        break;
      case "arrow1":
        editorName = "arrow";
        break;
      case "wedges1":
        editorName = "radialbasic";
        break;
    }
    navigate(`/editor/${editorName}/${asset_id}`, { state: { assetName: asset_name } });
  };

  const handlePublicCopyClick = () => {
    navigator.clipboard.writeText(publicKey);
    toast.success("✓ Your PUBLIC KEY has been copied to clipboard!", {
      position: "top-center",
      duration: 5000,
      style: {
        background: '#4caf50',
        color: '#fff',
        fontSize: '16px',
      },
    });
  };

  const handleSecretCopyClick = () => {
    navigator.clipboard.writeText(secretKey);
    toast.success("✓ Your SECRET KEY has been copied to clipboard!", {
      position: "top-center",
      duration: 5000,
      style: {
        background: '#4caf50',
        color: '#fff',
        fontSize: '16px',
      },
    });
  };

  const handleimgUrlCopyClick = () => {
    navigator.clipboard.writeText(imgUrl);
    toast.success("✓ Image URL has been copied to clipboard!", {
      position: "top-center",
      duration: 5000,
      style: {
        background: '#4caf50',
        color: '#fff',
        fontSize: '16px',
      },
    });
  };

  const handleBuildUrl = () => {
    const url = authTokenBuildUrl(currentAssetName, assetValue, function (fullUrl) {
      setImgUrl(fullUrl);
      console.log("fullUrl:", fullUrl)
      setUrlUpdated(true);  // Trigger the animation
      setTimeout(() => setUrlUpdated(false), 1000);  // Reset the animation state after 1 second
    });
  };

  const handleAddAsset = (event) => {
    setAssetName(event.target.value);
  };

  const handleAssetTypeButtonClick = (buttonId) => {
    setActiveButton(buttonId);
    let compid = 0;
    if (buttonId === "circle") {
      compid = 1;
    } else if (buttonId === "bar") {
      compid = 3;
    } else if (buttonId === "arrow") {
      compid = 5;
    }
    setSelectedComponentId(compid);
  };

  const handleButtonAddAssetClick = () => {
    if (selectedComponentId === 0) {
      toast.error('Please select a Component Type.');
      return;
    }

    const post_data = {
      asset_name: assetName,
      component_id: selectedComponentId,
      lsi_session_id: sessionToken,
    };
    apiAddAsset(post_data, function (data) {
      if (data && data.id && data.component_name) {
        setConceptState(conceptState + 1);
        toast.success('New Asset Added Successfully!');
        openEditor(data.component_name, data.id);
        setOpenNewAssetModal(false);
      } else {
        toast.error('Invalid Response While Attempting to Add Asset.');
      }
    });
  };

  const onOpenNewAssetModal = () => {
    setOpenNewAssetModal(true);
  };

  const onCloseNewAssetModal = () => {
    setOpenNewAssetModal(false);
  };

  const handleDownload = () => {
    if (imageRef.current === null) {
      return;
    }

    toPng(imageRef.current)
      .then((dataUrl) => {
        const link = document.createElement('a');
        link.download = 'image.png';
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.error('oops, something went wrong!', err);
      });
  };

  return (
    <div className="dashboard-manager-ma container-fluid dashboard-bg">
      <motion.div
        className="box"
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          duration: 1.5,
          ease: "easeOut",
          scale: {
            type: "spring",
            damping: 10,
            stiffness: 50,
            restDelta: 0.001
          }
        }}
      >
        <div className="d-flex flex-wrap justify-content-around align-items-center api-key mb-2 px-4">
          <div className="d-flex align-items-center">
            <div className="component-body">Public Key:</div>
            <div className="mx-2 api-key-container api-key-text">{publicKey}</div>
            <img
              src={CopyCode}
              className="copy-code-icon"
              alt="Copy"
              style={{ cursor: 'pointer' }}
              onClick={handlePublicCopyClick}
            />
          </div>
          <div className="d-flex align-items-center">
            <div className="component-body">Secret Key:</div>
            <div className="mx-2 api-key-container api-key-text">{secretKey}</div>
            <img
              src={CopyCode}
              className="copy-code-icon"
              alt="Copy"
              style={{ cursor: 'pointer' }}
              onClick={handleSecretCopyClick}
            />
          </div>
        </div>

        <div className="d-flex flex-wrap justify-content-around align-items-center add-new-asset-panel mb-2 px-4" onClick={onOpenNewAssetModal}>
          <div className="d-flex align-items-center">
            <div className="component-header">+Create New Asset</div>
          </div>
        </div>

        {allData.map(item => (
          <div
            key={item.asset_id}
            className="d-flex justify-content-between align-items-center box-shadow messy-styling bg-box-component px-lg-5 py-3 px-2 gap-3 mb-2 clickable"
            onClick={(e) => openEditor(item.component_name, item.asset_id, item.asset_name, e)}
            style={{ position: 'relative' }}
          >
            <div className="d-flex align-items-center gap-3">
              <img
                className="flex-shrink-0"
                src={item.image}
                alt=""
                style={{ height: 'auto', width: '40px', objectFit: 'cover' }}
              />
              <div className="d-flex flex-column align-items-start">
                <h3 className="m-0 d-inline-block component-title pl-2">
                  {item.asset_name}
                </h3>
              </div>
            </div>

            <div className="d-flex align-items-center gap-3">
              <div className="d-flex flex-column align-items-start" style={{ width: "166px" }}>
                <div className="d-flex align-items-start justify-content-start flex-row gap-2 disappear-on-small-screens">
                  <img
                    className="flex-shrink-0"
                    src={RadialIcon}
                    loading="lazy"
                    alt=""
                  />
                  <div className="d-inline-block component-body">
                    Active
                  </div>
                </div>
              </div>

              <button
                className="update-asset-btn api-key"
                onClick={(e) => onOpenRefreshModal(item.asset_id, item.asset_name, e)}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  border: 'none',
                  cursor: 'pointer',
                }}
                aria-label="Update Asset"
              >
                BUILD URL
              </button>

              <Modal
                open={openRefresh}
                onClose={handleCancelRefresh}
                center
                showCloseIcon={false}
                styles={{
                  modal: {
                    background: "rgb(25,25,34)",
                    background:
                      "radial-gradient(circle, rgba(25,25,34,1) 0%, rgba(21,21,28,1) 100%)",
                    borderRadius: "10px",
                  },
                  overlay: {
                    background: "rgba(0, 0, 0, 0.1)"
                  },
                }}
              >
                <div
                  className="d-flex flex-column"
                  style={{ overflow: "hidden", maxWidth: "100%" }}
                >
                  <div className="col-12 p-0">
                    <p className="toolbar-header gradient-toolbar mt-2 py-2 pl-3">
                      Dynamic URL Builder
                    </p>
                    <div className="col-12 d-flex justify-content-start align-items-baseline">
                      <label htmlFor="sizeInput" className="form-label component-title mr-4">Enter the value that you want to pass into this Asset:</label>
                        <input
                          type="text"
                          id="sizeInput"
                          name="size"
                          className="asset-value-input-field m-2"
                          value={assetValue}
                          onChange={handleInputChange}
                          placeholder=""
                        />
                        <button
                          className="btn confirm-btn"
                          onClick={handleBuildUrl}
                          style={{ height: '50px', width: '100px' }}
                        >
                          BUILD URL
                        </button>

                    </div>

                    <div className="d-flex justify-content-center mt-5 mb-4">
                      <img
                        src={imgUrl}
                        alt="Generated thumbnail"
                        style={{ width: 'auto', height: '200px', objectFit: 'cover' }}
                        ref={imageRef}
                      />
                    </div>

                    <img
                      src={DividerLine}
                      alt="Browser setup"
                      style={{ width: "100%", height: "auto" }}
                      className="mb-4"
                    />

                    <p className="toolbar-header gradient-toolbar mt-2 py-2 pl-3">
                    Image URL
                    </p>
                    <label htmlFor="sizeInput" className="form-label component-title mr-4 mb-4">Copy and paste this unique asset Image URL directly into your code:</label>
                    <div className={`d-flex align-items-center m-2 url-container ${urlUpdated ? 'fade-border' : ''}`}>
                      {/* <div className="component-body">Image URL:</div> */}
                      <div className="mx-2 url-container api-key-text">{imgUrl}</div>
                    </div>

                  </div>

                  <div className="d-flex justify-content-around mt-3">
                    <button
                      className="btn cancel-btn"
                      onClick={handleCancelRefresh}
                      style={{ height: '50px', width: '150px' }}
                    >
                      Cancel
                    </button>

                    <button
                      className="btn copy-url-btn"
                      onClick={handleimgUrlCopyClick}
                      style={{ height: '50px', width: '150px' }}
                    >
                      <img
                        src={CopyCode}
                        className="copy-code-icon pr-2"
                        alt="Copy"
                      />
                      COPY URL
                    </button>
                    {/* <button
                        className="btn download-btn"
                        onClick={handleDownload}
                      >
                        <img
                        src={DownloadPNG}
                        className="download-btn"
                        alt="Download PNG"
                      />
                      </button> */}
                  </div>
                </div>
              </Modal>

              <img
                className="trashcan-icon flex-shrink-0"
                src={TrashCan}
                loading="lazy"
                alt="Delete"
                onClick={(e) => onOpenDeleteModal(item.asset_id, item.asset_name, e)}
                style={{ cursor: "pointer" }}
              />

              <Modal
                open={openDelete}
                onClose={handleCancelDelete}
                center
                showCloseIcon={false}
                styles={{
                  modal: {
                    background: "rgb(18,17,19)",
                    background:
                      "linear-gradient(45deg, rgba(18,17,19,1) 0%, rgba(37,35,40,1) 38%, rgba(18,19,20,1) 100%)",
                    borderRadius: "5px",
                  },
                  overlay: {
                    background: "rgba(0, 0, 0, 0.1)"
                  },
                }}
              >
                <div className="d-flex flex-column justify-content-center p-2">
                  <p className="form-label doc-body-large mb-3">Are you sure you want to delete: "{currentAssetName}"?</p>
                  <img
                    src={DividerLine}
                    alt="Browser setup"
                    style={{ width: "100%", height: "auto" }}
                    className="mb-2"
                  />
                  <p className="form-label doc-body-medium mb-2 mt-2">Enter your Account password:</p>
                  <div>
                    <input
                      type="password"
                      value={password}
                      onChange={(e) => {
                        e.stopPropagation();
                        setPassword(e.target.value);
                      }}
                      onClick={(e) => e.stopPropagation()}
                      placeholder="Enter password"
                      className="input-field-asset-name p-3 mb-3"
                    />
                  </div>
                  <p className="form-label doc-body-medium mb-2">Then click confirm to delete this asset.</p>
                  <div className="d-flex justify-content-around mt-3">
                    <button
                      className="btn cancel-btn"
                      onClick={handleCancelDelete}
                      style={{ height: '50px', width: '150px' }}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn confirm-btn"
                      onClick={handleConfirmDelete}
                      style={{ height: '50px', width: '150px' }}
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        ))}

        <Modal
          open={openNewAssetModal}
          onClose={onCloseNewAssetModal}
          className="login-container"
          center
          showCloseIcon={false}
          styles={{
            modal: {
              background: "rgb(25,25,34)",
              background: "radial-gradient(circle, rgba(25,25,34,1) 0%, rgba(21,21,28,1) 100%)",
              borderRadius: "10px",
            },
          }}
        >
          <div className="d-flex flex-column justify-content-center align-items-center p-3">
            <div className="input-wrapper">
              <label htmlFor="asset-name" className="form-label input-label-text">
                Asset Name
              </label>
              <input
                type="text"
                id="asset-name"
                name="asset-name"
                className="input-field-asset-name p-3"
                value={assetName}
                onChange={handleAddAsset}
                placeholder="my-new-asset"
              />
            </div>
            <div className="login-inner d-flex justify-content-center">
              <img className="vector-icon" loading="lazy" alt="" src={DividerLine} />
            </div>
            <div className="welcome-wrapper">
              <h1 className="d-flex justify-content-center hero-body pt-3 py-1">
                Choose an Asset style
              </h1>
            </div>
            <div className="col-12 d-flex justify-content-center align-items-center mb-4">
              <button
                className={`new-asset-btn ${activeButton === "circle" ? "active" : ""}`}
                id="circle"
                onClick={() => handleAssetTypeButtonClick("circle")}
              >
                <img src={Circle} alt="Circle" />
              </button>
              <button
                className={`new-asset-btn ${activeButton === "bar" ? "active" : ""}`}
                id="bar"
                onClick={() => handleAssetTypeButtonClick("bar")}
              >
                <img src={Bar} alt="Bar" />
              </button>
              <button
                className={`new-asset-btn ${activeButton === "arrow" ? "active" : ""}`}
                id="arrow"
                onClick={() => handleAssetTypeButtonClick("arrow")}
              >
                <img src={Arrow} alt="Arrow" />
              </button>
            </div>
            <div className="group-frame d-flex justify-content-center flex-row">
              <div className="rectangle-parent">
                <div className="frame-inner" />
                <button className="cancel-btn" onClick={onCloseNewAssetModal}>
                  Cancel
                </button>
              </div>
              <div className="rectangle-group">
                <div className="rectangle-div" />
                <button className="create-btn" onClick={handleButtonAddAssetClick}>
                  +Create
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </motion.div>
    </div>
  );
};

export default DashboardManagerConcept;
