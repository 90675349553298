import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";
import EyeBall from "./images/eye-ball.png";
// Ensure these imports work; otherwise, use <img src={Xmark} alt="" /> style
import Xmark from "./images/x-mark.svg";
import CheckMark from "./images/check-mark.svg";
import { motion } from "framer-motion";

function Pricing() {

  return (
    <motion.div
        className="box"
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          duration: 1.5,
          ease: "easeOut",
          scale: {
            type: "spring",
            damping: 10,
            stiffness: 50,
            restDelta: 0.001
          }
        }}
      >
    <div className="container pt-5 mt-5">
      <div className="row pricing-row">
        <div className="col-12 d-flex justify-content-center flex-column">
          <h1 className="subheader-text d-flex justify-content-center">
            Pricing
          </h1>
          <p className="pricing-text mb-5 mt-2 justify-content-center">
          Welcome to our Pricing page! Explore our flexible subscription plans tailored for every need. Each tier offers unique access levels to our cutting-edge features, ensuring you find the perfect match for your needs.
          </p>
        </div>
      </div>
      <div className="row justify-content-center">
        {/* Free Access Section */}
        <div className="col-3 pricing-box component-title mr-2 d-flex justify-content-center">
          <div>
            <h2 className="pricing-title d-flex justify-content-center">Pilot Version</h2>
            <p className="d-flex justify-content-center subheader-text mt-3">$0</p>
            <p className="d-flex justify-content-center">Free</p>
            <ul className="no-bullets d-flex justify-content-center flex-column">
              <li>
                <img className="p-2" src={CheckMark} alt="Check mark" /> 3 Asset Designs
              </li>
              <li>
                <img className="p-2" src={CheckMark} alt="Check mark" /> 3 Asset Deployments
              </li>
            </ul>
            {/* <li>
              <button className="create-btn">Active</button>
            </li> */}
            <div className="d-flex justify-content-center">
              <button className="create-btn">Active</button>
            </div>
          </div>
        </div>

        {/* Basic Access Section */}
        <div className="col-3 pricing-box component-title mr-2 d-flex justify-content-center">
          <div className="justify-content">
            <h2 className="pricing-title">Professional</h2>
            <p className="d-flex justify-content-center subheader-text mt-3 text-blur">$15</p>
            <p className="d-flex justify-content-center">Monthly</p>
            <ul className="no-bullets d-flex justify-content-center flex-column">
              <li>
                <img className="p-2" src={CheckMark} alt="Check mark" />Coming Soon
              </li>
              <li>
                <img className="p-2" src={CheckMark} alt="Check mark" />Coming Soon
              </li>
            </ul>
          <div className="d-flex justify-content-center">
            <button className="coming-soon-btn">Coming Soon</button>
          </div>
          </div>
        </div>

        {/* Full Access Section */}
        <div className="col-3 pricing-box component-title mr-1 d-flex justify-content-center">
          <div>
            <h2 className="pricing-title d-flex justify-content-center">Premium</h2>
            <p className="d-flex justify-content-center subheader-text mt-3 text-blur">$30</p>
            <p className="d-flex justify-content-center">Monthly</p>
            <ul className="no-bullets d-flex justify-content-center flex-column">
              <li>
                <img className="p-2" src={CheckMark} alt="Check mark" />Coming Soon
              </li>
              <li>
                <img className="p-2" src={CheckMark} alt="Check mark" />Coming Soon
              </li>
            </ul>
            <div className="d-flex justify-content-center">
            <button className="coming-soon-btn">Coming Soon</button>
          </div>
          </div>
        </div>

        {/* Full Access Section */}
        <div className="col-3 pricing-box component-title mr-1 d-flex justify-content-center">
          <div>
            <h2 className="pricing-title d-flex justify-content-center">Business</h2>
            <p className="d-flex justify-content-center subheader-text mt-3 text-blur">$100</p>
            <p className="d-flex justify-content-center">Monthly</p>
            <ul className="no-bullets d-flex justify-content-center flex-column">
              <li>
                <img className="p-2" src={CheckMark} alt="Check mark" />Coming Soon
              </li>
              <li>
                <img className="p-2" src={CheckMark} alt="Check mark" />Coming Soon
              </li>
            </ul>
            <div className="d-flex justify-content-center">
            <button className="coming-soon-btn">Coming Soon</button>
          </div>
          </div>
        </div>

        <div className="col-3 pricing-box component-title mr-1 d-flex justify-content-center ">
          <div>
            <h2 className="pricing-title d-flex justify-content-center">Enterprise</h2>
            <p className="d-flex justify-content-center subheader-text text-blur">Custom</p>
            <p className="d-flex justify-content-center">Contact us</p>
            <ul className="no-bullets d-flex justify-content-center flex-column">
              <li>
                <img className="p-2" src={CheckMark} alt="Check mark" />Coming Soon
              </li>
              <li>
                <img className="p-2" src={CheckMark} alt="Check mark" />Coming Soon
              </li>
            </ul>
            <div className="d-flex justify-content-center">
            <button className="coming-soon-btn">Coming Soon</button>
          </div>
          </div>
        </div>
      </div>
    </div>
    </motion.div>
  );
}

export default Pricing;
