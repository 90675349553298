import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState, useRef } from "react";
import { useParams, useLocation } from "react-router-dom";
import axios from "axios";
import { apiGetOneAsset, apiSaveOneAsset } from "../Components/ApiClient";
import toast from "react-hot-toast";
import { motion } from "framer-motion";
import { phpSessGet, phpSessSet, phpSessLogout, phpSessIsLoggedIn } from '../Components/PhpSession.js'

import TransparentBG from "../Components/images/Transparency500.png";
import HistoryBack from "../Components/images/history-back-icon.svg";
import HistoryForward from "../Components/images/history-forward-icon.svg";
import SaveAsset from "../Components/images/save-asset-icon.svg";
import StageAsset from "../Components/images/stage-asset-icon.svg";
import BackBtn from "../Components/images/back-btn.svg";
import { useNavigate } from "react-router-dom";
import Edit from "../Components/images/edit.png";

const Arrow = () => {
  const all_params = useParams();
  const isMounted = useRef(false);
  const [assetInputName, setAssetInputName] = useState("");
  const location = useLocation();
  const { assetName, sessID } = location.state || {};
  const navigate = useNavigate();

  // <---------------------------State variable declarations---------------------------->
  const [username, setUsername] = useState("");
  const [size, setSize] = useState("");
  const [cutOffValue, setCutOffValue] = useState("");
  const [arrowUpColor, setArrowUpColor] = useState("#3d3d42");
  const [arrowDownColor, setArrowDownColor] = useState("#3d3d42");
  const [shadowColor, setShadowColor] = useState("#29292D");
  const [fontFamily, setFontFamily] = useState("bebasneue-regular");
  const [fontColor, setFontColor] = useState("#FFFFFF");
  const [dynamicImgUrl, setDynamicImgUrl] = useState("");
  const [arrowData, setArrowData] = useState({});
  const [ranges, setRanges] = useState({});
  const [backgroundStyle, setBackgroundStyle] = useState({
    backgroundColor: "white",
    backgroundImage: "",
  });
  const [isEditing, setIsEditing] = useState(false);

  //<-----------------Axios call to retrieve user's last design------------------>
  const retrieveUserLastDesign = () => {
    apiGetOneAsset(all_params.asset_id, function (data) {
      // console.log("data:", data);
      //use data.asset_name, data.first_name, data.last_name
      const params = JSON.parse(data.params);

      setDynamicImgUrl(data.image);

      // Updating the state with the parsed params
      setSize(params.full_w);
      setArrowUpColor(params.up_c);
      setArrowDownColor(params.down_c);
      setFontColor(params.font_c);
      setFontFamily(params.font_fam);
      setCutOffValue(params.cutoff_v);
      setRanges(params.ranges);
    });
  };

  useEffect(() => {
    if (sessID) {
      phpSessSet(sessID); // Ensure the session ID is set
    }
    setAssetInputName(assetName);
    retrieveUserLastDesign();
  }, [sessID, assetName]);

  //<---------------Use Effects to Update State onClick---------------------->

  useEffect(() => {
    if (isMounted.current) {
      if (size !== "#3d3d42" ||
          arrowUpColor !== "#3d3d42" ||
          arrowDownColor !== "#3d3d42" ||
          fontColor !== "#3d3d42" ||
          fontFamily !== "#3d3d42" ||
          cutOffValue !== "xl") {
        newRenderComponentClick();
      }
    } else {
      isMounted.current = true;
    }
  }, [
    size,
    arrowUpColor,
    arrowDownColor,
    fontColor, 
    fontFamily,
    cutOffValue,
    // ranges
  ]);

  //<-------Event handler to trigger axios call when Render Component is clicked--------->
  const renderComponentClick = () => {};

  const newRenderComponentClick = () => {
    // console.log("NewRenderComponentClick");
    const post_data = {
      asset_id: all_params.asset_id,
      full_w: size,
      up_c: arrowUpColor,
      down_c: arrowDownColor,
      font_c: fontColor,
      font_fam: fontFamily,
      cutoff_v: cutOffValue,
      // ranges: [0, 25],
    };
    apiSaveOneAsset(post_data, function (data) {
      setDynamicImgUrl(data.image);
      console.log("POST DATA", post_data)
    });
  };

  // const resetState = () => {
  //   setSize("");
  //   setArrowUpColor("#3d3d42");
  //   setArrowDownColor("#3D3D42");
  //   setFontColor("#29292D");
  //   setShadowColor("#29292D");
  // };

  // <-------------------Event Handlers--------------------------->

  const handleColorChange = (colorSetter, event) => {
    colorSetter(event.target.value);
    // NewRenderComponentClick();
  };

  const handleInputChange = (event) => {
    setSize(event.target.value);
    // NewRenderComponentClick();
  };

  const handleAssetInputNameChange = (event) => {
    setAssetInputName(event.target.value);
  };

  const handleCutOffValue = (event) => {
    setCutOffValue(event.target.value);
    // NewRenderComponentClick();
  };

  const handleFontFamilyChange = (event) => {
    setFontFamily(event.target.value);
    // NewRenderComponentClick();
  };

  const handleFontColorChange = (event) => {
    setFontColor(event.target.value);
    // NewRenderComponentClick();
  };

  const handleWhiteBackground = () => {
    setBackgroundStyle({
      backgroundColor: "white",
      backgroundImage: "",
    });
  };

  const handleBlackBackground = () => {
    setBackgroundStyle({
      backgroundColor: "black",
      backgroundImage: "",
    });
  };

  const handleShadowBackground = () => {
    setBackgroundStyle({
      backgroundColor: shadowColor,
      backgroundImage: "", // Use the imported TpBg image here
    });
  };

  useEffect(() => {
    handleShadowBackground();
  }, [shadowColor]);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    setAssetInputName(assetName);
  };

  const handleConfirmClick = () => {
    // Placeholder for future server-side logic
    toast.success('Asset Name Updated!');
    setIsEditing(false);
  };

  // const handleUsernameInputChange = (e) => {
  //   setUsername(e.target.value);
  //   NewRenderComponentClick();
  // };
  //<------------------------------------------------------------------------------------>

  return (
    <motion.div
      className="box"
      initial={{ opacity: 0, scale: 0.95 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{
        duration: 1.5,
        ease: "easeOut",
        scale: {
          type: "spring",
          damping: 10,
          stiffness: 50,
          restDelta: 0.001
        }
      }}
    >
      <div className="d-flex justify-content-center align-items-center pt-5">
        <div className="container-fluid bg-main pb-5 mt-5">
          <div className="row mx-2">
            <div className="d-flex flex-wrap justify-content-center">
              {/* Left half for the image */}
              <div className="col-lg-6 col-md-6 col-sm-12 gradient-arrow-container drop-shadow mx-1 my-1" style={{ minWidth: '350px' }}>
              <div className="col-12 p-0 position-relative d-flex justify-content-center">
                  <div className="d-flex align-items-center justify-content-center my-3" style={{ width: '100%' }}>
                    <img
                      className="clickable back-arrow-btn"
                      loading="lazy"
                      alt="back-btn"
                      src={BackBtn}
                      onClick={() => navigate("/dashboard")}
                      style={{ marginRight: '8px' }}
                    />
                    <input
                      type="text"
                      id="assetNameInput"
                      name="assetName"
                      className={`form-control flex-grow-1 asset-name-field ${!isEditing ? 'no-pointer-events' : ''}`}
                      value={assetInputName}
                      onChange={handleAssetInputNameChange}
                      placeholder="my-asset-name"
                    />
                    {isEditing ? (
                      <>
                        <button onClick={handleCancelClick} className="btn text-danger name-change ml-2">✕</button>
                        <button onClick={handleConfirmClick} className="btn text-success name-change ml-2">✓</button>
                      </>
                    ) : (
                      <img
                        className="clickable edit-asset-name-btn ml-2"
                        loading="lazy"
                        alt="Edit Asset Name"
                        src={Edit}
                        onClick={handleEditClick}
                      />
                    )}
                  </div>
                </div>
  
                <div className="d-flex justify-content-center pb-2">
                  {/* Row for the larger image */}
                  <div
                    className="d-flex justify-content-center main-img-container"
                    style={{
                      ...backgroundStyle,
                      width: "auto",
                      height: "70%",
                      border: "1px solid #ccc",
                      overflow: "hidden"
                    }}
                  >
                    <img src={dynamicImgUrl} alt="Dynamic" className="radial-main" />
                  </div>
                </div>
  
                <div className="d-flex justify-content-center mt-3 mb-3">
                  {/* Row for the smaller images */}
                  <div
                    className="d-flex justify-content-center flex-wrap"
                    style={{ width: "100%" }}
                  >
                    <div className="col d-flex justify-content-center flex-wrap" style={{ overflow: "hidden" }}>
                      {dynamicImgUrl && (
                        <img
                          src={dynamicImgUrl}
                          alt="Dynamic Light"
                          className="bg-light p-1"
                          style={{
                            maxWidth: "20%",
                            height: "auto",
                            cursor: "pointer",
                            margin: "5px",
                          }}
                          onClick={handleWhiteBackground}
                        />
                      )}
                      {dynamicImgUrl && (
                        <img
                          src={dynamicImgUrl}
                          alt="Dynamic Dark"
                          className="p-1"
                          style={{
                            maxWidth: "20%",
                            height: "auto",
                            cursor: "pointer",
                            margin: "5px",
                          }}
                          onClick={handleBlackBackground}
                        />
                      )}
                      {dynamicImgUrl && (
                        <img
                          src={dynamicImgUrl}
                          alt="Dynamic Shadow Color"
                          className="p-1"
                          style={{
                            maxWidth: "20%",
                            height: "auto",
                            backgroundColor: shadowColor,
                            cursor: "pointer",
                            margin: "5px",
                          }}
                          onClick={handleShadowBackground}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
  
              {/* Right half for the rest of the content */}
              <div className="col-lg-6 col-md-6 col-sm-12 gradient-arrow-container drop-shadow mx-1 my-1" style={{ minWidth: '350px' }}>
                {/* Size Box */}
                <div className="col-12 p-0">
                  <p className="toolbar-header gradient-toolbar mt-2 py-2 pl-3">
                    Size
                  </p>
                  <div className="col-12 d-flex justify-content-between align-items-center  toolbar-hover pb-2">
                    <label htmlFor="sizeInput" className="toolbar-text">
                      Total Width
                    </label>
                    <input
                      type="text"
                      id="sizeInput"
                      name="size"
                      className="diameter-input-field"
                      value={size}
                      onChange={handleInputChange}
                      placeholder="pixels"
                    />
                  </div>
                </div>
  
                {/* Font Box */}
                <div className="col-12 p-0">
                  <h5 className="toolbar-header gradient-toolbar py-2 pl-3">
                    Font
                  </h5>
                  {/* Font Family */}
                  <div className="col-12 d-flex justify-content-between align-items-center divider-line toolbar-hover py-2">
                    <label htmlFor="sizeInput" className="toolbar-text">
                      Font Family
                    </label>
                    <select
                      id="fontFamily"
                      name="fontFamily"
                      className="input-field-font"
                      value={fontFamily}
                      onChange={handleFontFamilyChange}
                    >
                      <option
                        className="bebasneue-regular"
                        value="bebasneue-regular"
                      >
                        Bebasneue-Regular
                      </option>
                      <option className="bebasneue-bold" value="bebasneue-bold">
                        Bebasneue-Bold
                      </option>
                      <option
                        className="bungeehairline-regular"
                        value="bungeehairline-regular"
                      >
                        Bungee Hair Line-Regular
                      </option>
                      <option
                        className="jetbrains-mono-regular"
                        value="jetbrains-mono-regular"
                      >
                        Jetbrains-Mono-Regular
                      </option>
                      <option
                        className="jetbrains-mono-bold"
                        value="jetbrains-mono-bold"
                      >
                        Jetbrains-Mono-Bold
                      </option>
                      <option className="lato-regular" value="lato-regular">
                        Lato-Regular
                      </option>
                      <option className="lato-bold" value="lato-bold">
                        Lato-Bold
                      </option>
                      <option
                        className="leaguespartan-regular"
                        value="leaguespartan-regular"
                      >
                        League Spartan-Regular
                      </option>
                      <option
                        className="leaguespartan-bold"
                        value="leaguespartan-bold"
                      >
                        League Spartan-Bold
                      </option>
                      <option className="lexend-regular" value="lexend-regular">
                        Lexend-Regular
                      </option>
                      <option className="lexend-bold" value="lexend-bold">
                        Lexend-Bold
                      </option>
                      <option
                        className="montserrat-regular"
                        value="montserrat-regular"
                      >
                        Montserrat-Regular
                      </option>
                      <option className="montserrat-bold" value="montserrat-bold">
                        Montserrat-Bold
                      </option>
                      <option
                        className="robocondensed-regular"
                        value="robotocondensed-regular"
                      >
                        Roboto-Condensed-Regular
                      </option>
                      <option
                        className="robocondensed-bold"
                        value="robotocondensed-bold"
                      >
                        Robocondensed-Bold
                      </option>
                    </select>
                  </div>
                  {/* {/* Font Color */}
                  <div className="col-12 d-flex justify-content-between align-items-center divider-line toolbar-hover py-2">
                    <label htmlFor="fontColor" className="toolbar-text">
                      Font Color
                    </label>
                    <input
                      type="color"
                      id="fontColor"
                      name="fontColor"
                      value={fontColor}
                      onChange={handleFontColorChange}
                      className="square-color-picker"
                    />
                  </div>
                </div>
  
                {/* -------------Dynamic Arrow------------- */}
  
                <div className="col-12 p-0">
                  <h5 className="toolbar-header gradient-toolbar mt-2 py-2 pl-3">
                    Dynamic Ring
                  </h5>
                  {/* Font Family */}
                  <div className="col-12 d-flex justify-content-between align-items-center divider-line toolbar-hover pb-2">
                    <label className="toolbar-text">Up Color</label>
                    <input
                      type="color"
                      id="innerRingColor"
                      name="innerRingColor"
                      value={arrowUpColor}
                      onChange={(e) => handleColorChange(setArrowUpColor, e)}
                      className="square-color-picker"
                    />
                  </div>
  
                  {/* Font Color */}
                  <div className="col-12 d-flex justify-content-between align-items-center divider-line toolbar-hover py-2">
                    <label className="toolbar-text">Down Color</label>
                    <input
                      type="color"
                      id="innerRingOffColor"
                      name="innerRingOffColor"
                      value={arrowDownColor}
                      onChange={(e) => handleColorChange(setArrowDownColor, e)}
                      className="square-color-picker"
                    />
                  </div>
  
                  {/* Cut-off Value */}
                  <div className="col-12 d-flex justify-content-between align-items-center toolbar-hover py-2">
                    <label htmlFor="sizeInput" className="toolbar-text">
                      Cutoff Value
                    </label>
                    <input
                      type="text"
                      id="sizeInput"
                      name="size"
                      className="diameter-input-field"
                      value={cutOffValue}
                      onChange={handleCutOffValue}
                      placeholder="#"
                    />
                  </div>
                </div>
                {/* <div className="d-flex justify-content-center">
                  <button
                    onClick={NewRenderComponentClick}
                    className="render-btn"
                  >
                    Render Component
                  </button>
                  <button onClick={resetState} className="reset-btn">
                    Reset
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Arrow;
